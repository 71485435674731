@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.projectPlaylistContainer
	@include innerGridContainer(5)
	z-index: 10

.playlistPassive
	.projectPlaylistCover
		clip-path: inset(0px 100% 100% 0px round $small-border-radius)

		& > div
			transform: scale(8.88)
	
	.projectPlaylistSummary
		opacity: 0

.projectPlaylist
	@include fullAbsolute
	z-index: 20
	display: flex

.projectPlaylistCover
	width: 10vh
	height: 10vh
	flex-basis: 10vh
	border-radius: $small-border-radius
	clip-path: inset(0px 0px 0px 0px round $small-border-radius)
	transition: .7s $secondary-ease-in

	& > div
		width: 10vh
		height: 10vh
		background-size: cover
		background-position: center
		transform: scale(1)
		transition: 1s $secondary-ease-in

.projectPlaylistSummary
	width: 40vh
	flex-basis: 40vh
	padding-left: 20px
	display: flex
	flex-direction: column
	justify-content: center
	@include responsiveHeightText($sm-title-text)
	line-height: 1.44
	letter-spacing: -0.5px
	font-family: 'Circular Std'
	font-weight: 300
	opacity: 1
	transition: .7s $secondary-ease-in .1s

	h4
		font-weight: inherit

	a
		color: $green
		font-weight: 500

		@include hoverFocusActiveState
			color: $green
