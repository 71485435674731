@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.trackNav
	@include mobileModalContentNav

.navActions
	display: flex
	align-items: center

	a
		display: inline-flex
		align-items: center

.previewControl
	margin-left: 5px
	display: inline-flex
	align-items: center
	color: $green
	font-size: $regular-text
	font-family: 'Circular Std'
	line-height: 1.25
	letter-spacing: -0.67px
	font-weight: 500

	@include respondTo('size-xs')
		margin-left: 15px

	svg
		width: 24px
		height: 24px
		margin-right: 5px

.albumCoverBg
	position: absolute
	top: 0
	right: 0
	left: 0
	height: 100vw
	min-height: 375px
	clip-path: inset(0 0 0 0)

	@include after
		position: absolute
		top: 0
		right: 0
		bottom: -2px
		left: 0
		background: linear-gradient(rgba(25,25,26,0.5) 0%, #19191A 100%)

	& > div
		width: 100vw
		height: 100vw
		background-size: cover
		background-position: center
		transform: scale(2.13)

.trackSummary
	position: relative
	padding: 60px 8vw 0
	z-index: 5

.albumCover
	width: $mobile-track-details-album-dim
	height: $mobile-track-details-album-dim
	background-size: cover
	background-position: center
	margin: auto

.title
	margin: 20px 0 15px
	text-align: center
	font-size: $mobile-modal-title-text-sm
	font-family: 'Circular Std'
	line-height: 1.25
	letter-spacing: -1.33px
	font-weight: 700
	word-break: break-word

.artistGenres
	text-align: center

	span
		height: $mobile-pill-height
		background: transparentize($white, .9)
		border-radius: 35px
		display: inline-flex
		align-items: center
		padding: 0 15px
		font-size: $regular-text
		line-height: 1.5
		font-weight: 500

		&:nth-child(2)
			border-radius: 25px
			margin-left: 10px
			letter-spacing: -0.5px

.playlistButton
	margin-top: 20px
	height: $modal-btn-height
	background: $mild-green
	font-size: $sm-title-text
	line-height: 1.33
	opacity: 1
	transform: translate(0, 0)
	animation: none

	svg
		transform: scale(1)

.quickStats
	margin: 30px 0 40px

	nav
		display: flex
		justify-content: center

.artists
	margin: 25px 8vw 0

.audioFeaturesContainer
	margin-bottom: 40px

.featuresCards
	padding: 0 8vw
	display: grid
	grid-template-columns: auto auto
	grid-gap: 15px
