@import '../../sass/abstracts/mixins.sass'

.firstLine
    display: inline-block

.singleLine
    width: 100%
    @include ellipsis

.lastLine
    display: inline-block
    width: 100%
    @include ellipsis