@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.playlistCreator
	@include responsiveModalGrid(360px, 630px, 90px)
	padding: 90px 0 65px

.settingsToggle
	position: absolute
	top: -60px
	left: 120px
	padding-left: 20px
	border-left: thin solid transparentize($gray-alt, .6)
	height: $modal-action-height
	display: flex
	align-items: center
	animation: fade-in .7s $secondary-ease-in

	& > span
		display: flex
		align-items: center
		flex-shrink: 0
		padding: 7px 15px 7px 10px
		background: transparentize($green, 1)
		@include responsiveHeightText($sm-title-text)
		line-height: 1.28
		letter-spacing: -0.75px
		font-family: 'Circular Std'
		font-weight: 500
		opacity: 0
		border-radius: $large-border-radius
		transform: translate3d(0, 30px, 0)
		transition: .7s $secondary-ease-in .1s
		animation: swipe-up .7s $secondary-ease-in forwards

		path
			transition: .7s $secondary-ease-in .1s

		&.isActive
			background: transparentize($green, .85)
			color: $green
			transition-delay: 0s

			path
				fill: $green
				transition-delay: 0s

		svg
			@include scaleWidthHeight(30px, 30px)
			margin-right: 8px

			path
				fill: $white

.fadeOut
	animation: fade-out .1s $secondary-ease-in forwards

.hasSubmitted
	.playlistButton, .spotifyButton, .moreSelect
		animation-duration: .5s

		& > span
			animation-duration: .5s

.playlistCover
	@include scaleWidthHeight($modal-main-cover-dim, $modal-main-cover-dim)
	position: relative
	overflow: hidden
	clip-path: inset(0px 100% 100% 0px round $medium-border-radius $medium-border-radius)
	animation: mask-out .7s $secondary-ease-in forwards

	& > div
		width: 100%
		height: 100%
		background-size: cover
		background-position: center
		transform: scale3d(2.2, 2.2, 2.2)
		animation: main-cover-scale-down 1.5s $secondary-ease-in forwards

.playlistButton
	margin-top: 15px
	background: $mild-green
	opacity: 0
	transform: translate3d(0, 105px, 0)
	animation: swipe-up 1s $secondary-ease-in .1s forwards

	& > span
		transform: translate3d(0, 205%, 0)
		animation: swipe-up 1s $secondary-ease-in .1s forwards

.playlistOtherActions
	margin-top: 15px
	display: flex

.spotifyButton
	background: $dark-ash
	color: $green
	opacity: 0
	transform: translate3d(0, 105px, 0)
	animation: swipe-up 1s $secondary-ease-in .2s forwards

	&:hover
		color: $green

	path
		fill: $green

	& > span
		transform: translate3d(0, 205%, 0)
		animation: swipe-up 1s $secondary-ease-in .2s forwards

.moreSelect
	display: inline-flex
	justify-content: center
	align-items: center
	margin: 10px 0 0 15px
	padding: 10px
	background: $dark-ash
	border-radius: $medium-border-radius
	opacity: 0
	transform: translate3d(0, 105px, 0)
	animation: swipe-up 1s $secondary-ease-in .3s forwards

	svg
		@include scaleWidthHeight(30px, 30px)

.playlistActionProgress
	position: absolute
	right: 0
	left: 0

.fadeOutActions
	animation: fade-out .5s $secondary-ease-in forwards
	pointer-events: none

.filterFormContainer
	height: calc(80vh - 150px)
	overflow: hidden

.exitFilterFormContainer
	opacity: 0
	transform: translate(0, 80px)
	transition: opacity .1s $secondary-ease-in, transform .5s $secondary-ease-in

.filterFormTopOverlay
	position: absolute
	top: 0
	right: 0
	left: 0
	height: 90px
	background: linear-gradient(0deg, rgba(25,25,26,0) 0%, #19191A 100%)
	pointer-events: none
	transition: 1.5s $secondary-ease-in
	z-index: 40

.filterForm
	padding: 15px 0 90px

	& > div
		transform: translate3d(0, 80px, 0)
		animation: swipe-up .7s $secondary-ease-in forwards .1s

	h3
		@include responsiveHeightText($x-large-text)
		line-height: 1.25
		letter-spacing: -2.22px
		font-family: 'Circular Std'
		font-weight: 900
		opacity: 0
		transform: translate3d(0, 80px, 0)
		animation: swipe-up .7s $secondary-ease-in forwards

		& > span
			transform: translate3d(0, 42px, 0)
			animation: swipe-up .7s $secondary-ease-in forwards

	form
		margin-top: 15px

	legend
		opacity: 0
		transform: translate3d(0, 80px, 0)
		animation: swipe-up .7s $secondary-ease-in forwards

		& > span
			display: flex
			align-items: center

		svg
			@include scaleWidthHeight(20px, 20px)
			margin-right: 7px

			path
				fill: $mild-ash

.sourceFieldSet
	legend
		animation-delay: .1s

	& > p
		opacity: 0
		transform: translate3d(0, 80px, 0)
		animation: swipe-up .7s $secondary-ease-in forwards .3s

.playlistSource
	margin-top: 15px
	@include scaleWidth(360px)
	opacity: 0
	transform: translate3d(0, 80px, 0)
	animation: swipe-up .7s $secondary-ease-in forwards .2s

.tracksCountFieldSet
	margin-top: 15px

	legend
		animation-delay: .2s

.tracksCount
	margin-top: 15px
	display: grid
	grid-template-columns: 1fr 1fr 1fr 1fr
	grid-column-gap: 15px

	& > label
		@include scaleHeight(80px)
		opacity: 0
		transform: translate3d(0, 80px, 0)
		animation: swipe-up .7s $secondary-ease-in forwards .3s

		&:nth-child(2)
			animation-delay: .4s

		&:nth-child(3)
			animation-delay: .5s

		&:nth-child(4)
			opacity: 0
			animation: disabled-swipe-up .7s $secondary-ease-in forwards .6s

.audioFeatureFieldSet
	margin-top: 15px

	&:not(.isPassive)
		legend
			animation: swipe-up .7s $secondary-ease-in forwards

		.audioFeatures > label
			animation: swipe-up .7s $secondary-ease-in forwards .1s

			&:nth-child(2)
				animation-delay: .2s

			&:nth-child(3)
				animation-delay: .3s

	legend
		animation: none

.audioFeatures
	margin-top: 15px
	display: grid
	grid-template-columns: 1fr 1fr 1fr
	grid-column-gap: 15px

	& > label
		@include scaleHeight(60px)
		opacity: 0
		transform: translate3d(0, 80px, 0)

.filterFormActions
	position: absolute
	top: calc(80vh - 150px)
	right: 0
	left: 0
	transform: translate(0, -100%)
	display: flex
	flex-direction: column
	justify-content: flex-end

.filterFormActionsOverlay
	position: absolute
	top: 0
	right: 0
	left: 0
	height: 90px
	background: linear-gradient(180deg, rgba(25,25,26,0) 0%, #19191A 100%)
	pointer-events: none
	transform: translate3d(0, -100%, 0)
	transition: 1.5s $secondary-ease-in
	animation: fade-in .6s $secondary-ease-in

.filterFormActionsButtons
	display: grid
	grid-template-columns: 1fr 1fr
	grid-column-gap: 15px
	background: $mild-dark-ash-alt
	transform: translateZ(0)

.actionButton
	@include responsiveHeightText($sm-title-text)
	line-height: 1.33
	border-radius: $medium-border-radius
	opacity: 0
	transform: translate3d(0, 80px, 0)
	animation: swipe-up .7s $secondary-ease-in forwards

.applyButton
	background: $mild-green
	color: $white
	animation-delay: .4s

.closeButton
	background: $dark-ash
	color: $green
	animation-delay: .5s

.scrollBar
	position: absolute
	top: 40px
	right: -45px
	height: calc(80vh - 270px)
	width: $scrollbarWidth
	background: transparentize($white, .9)
	border-radius: $small-border-radius
	opacity: 1
	transition: .5s $secondary-ease-in .6s
	z-index: 10

	&.disabledBar
		opacity: .39

.hideScrollBar
	opacity: 0
	transition: 0s

.scrollThumb
	position: absolute
	top: 0
	right: 0
	left: 0
	border-radius: $small-border-radius
	overflow: hidden

	@include after
		@include fullAbsolute
		background: $mild-green
		transform: scale3d(1, 1, 1)
		transform-origin: top
		transition: .5s $secondary-ease-in .7s

.hideThumb
	&:after
		transform: scale3d(1, 0, 1)
		transition: none

.playlistData
	position: relative

.regenerateLoader
	top: -90px

.playlistTitle
	margin: 0
	@include responsiveHeightText($modal-title-text - 10)
	line-height: 1.14
	letter-spacing: -3.89px
	font-family: 'Circular Std'
	font-weight: 900
	overflow: hidden
	opacity: 0
	transform: translate3d(0, 40px, 0)
	animation: swipe-up .7s $secondary-ease-in .3s forwards

	& > span
		display: inline-block
		transform: translate3d(0, 138%, 0)
		animation: swipe-up .7s $secondary-ease-in .3s forwards

.activeFilters
	margin-bottom: 20px
	display: flex
	align-items: center
	flex-wrap: wrap
	@include responsiveHeightText($regular-text)
	line-height: 2.5
	font-weight: 500

	& > span
		opacity: 0
		transform: translate3d(0, 45px, 0)
		animation: swipe-up .7s $secondary-ease-in forwards

		&:nth-child(1)
			animation-delay: .4s

		&:nth-child(2)
			animation-delay: .45s

		&:nth-child(3)
			animation-delay: .5s

		&:nth-child(4)
			animation-delay: .55s

		&:nth-child(5)
			animation-delay: .6s

		&:nth-child(6)
			animation-delay: .65s

.playlistSourceTag
	margin: 20px 10px 0 0
	background: $green
	padding: 0 20px
	color: $black
	border-radius: $regular-border-radius + 5

.playlistTag
	margin: 20px 10px 0 0
	display: inline-flex
	align-items: center
	background: transparentize($green, .9)
	padding: 0 20px 0 10px
	border-radius: $regular-border-radius + 5

	& > svg
		@include scaleWidthHeight(22px, 22px)
		margin-right: 8px

		path
			fill: $green

.trackCard
	display: flex
	justify-content: space-between
	@include scaleHeight($playlist-track-card-height)
	background: $dark-ash
	border-radius: $medium-border-radius
	opacity: 0
	transform: translate3d(0, 50px, 0)
	transition: .7s $secondary-ease-in

	&:not(.isPassive)
		opacity: 1
		transform: translate3d(0, 0, 0)

		.coverImage > div
			transform: scale3d(1, 1, 1)

	&:not(:last-child)
		margin-bottom: 15px

.pushUp
	&:not(.isPassive)
		animation: push-up .35s $secondary-ease-in

.trackInfo
	width: calc(100% - 115px)
	flex-basis: calc(100% - 115px)
	display: flex
	align-items: center
	overflow: hidden

.removeTrack
	padding: 0 15px
	flex-shrink: 0

	& > svg
		@include scaleWidthHeight(30px, 30px)

.coverImage
	@include scaleWidthHeight($playlist-track-card-height, $playlist-track-card-height)
	overflow: hidden

	& > div
		width: 100%
		height: 100%
		background-size: cover
		background-position: center
		transform: scale3d(3, 3, 3)
		transition: 1s $secondary-ease-in

.trackDetails
	margin-left: 20px
	width: calc(100% - 170px)
	flex-basis: calc(100% - 170px)
	display: inline-flex
	flex-direction: column
	overflow: hidden

	h3
		margin: 0 0 4px
		@include responsiveHeightText($playlist-track-name-text)
		line-height: 1.28
		letter-spacing: -0.61px
		font-family: 'Circular Std'
		font-weight: 700
		@include ellipsis

	p
		margin: 0
		color: $mild-gray
		@include responsiveHeightText($regular-text)
		line-height: 1.31
		font-weight: 500
		@include ellipsis

.trackActions
	width: 115px
	flex-basis: 115px
	padding-right: 22px
	display: flex
	justify-content: flex-end
	align-items: center

	a
		margin-left: 15px
		display: inline-flex
		align-items: center

		& > span
			@include scaleWidthHeight(30px, 30px)

.audioControl
	@include scaleWidthHeight(30px, 30px)
	@include fullCenter
	border: $regular-border solid $green
	border-radius: 50%

	& > span
		width: $playlist-track-audio-icon-dim
		height: $playlist-track-audio-icon-dim
		@include scaleSvg

@keyframes mask-out
	to
		clip-path: inset(0px 0px 0px 0px round $medium-border-radius)

@keyframes main-cover-scale-down
	to
		transform: scale3d(1, 1, 1)

@keyframes fade-in
	from
		opacity: 0

	to
		opacity: 1

@keyframes fade-out
	from
		opacity: 1

	to
		opacity: 0

@keyframes swipe-up
	to
		opacity: 1
		transform: translate3d(0, 0, 0)

@keyframes disabled-swipe-up
	to
		opacity: .3
		transform: translate3d(0, 0, 0)

@keyframes push-up
	from
		transform: translate3d(0, 100%, 0)

	to
		transform: translate3d(0, 0, 0)
