@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.sectionTitle
	margin: 0 0 20px
	padding: 0 8vw
	text-align: center
	font-size: $mobile-modal-section-title-text
	font-family: 'Circular Std'
	letter-spacing: -0.98px
	line-height: 1.66
	font-weight: 700
	opacity: 1
	transform: translate3d(0, 0, 0)
	transition: .7s $secondary-ease-in

.isPassive
	opacity: 0
	transform: translate3d(0, 200px, 0)