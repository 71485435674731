@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.container
	margin-top: 40px
	padding: 0 8vw

.userSummary
	margin: 10px 0

.topTrackCaption
	@include mobileSectionCaptionText

	& > span
		display: inline-block
		transition: 1s $secondary-ease-in .1s
		transform: translate(0, 0)

.topTrackArtistName
	color: $green

.topTrackSubcaption
	@include mobileSectionSubcaptionText

	& > span
		display: inline-block
		transition: 1s $secondary-ease-in .2s
		transform: translate(0, 0)

.tracksGrid
	display: grid
	grid-template-columns: auto auto
	grid-gap: 15px
	margin-bottom: 15px

.listActions
	margin-bottom: 10px
	display: grid
	grid-template-columns: calc((100% - 15px) * 0.3) calc((100% - 15px) * 0.7)
	grid-gap: 15px
