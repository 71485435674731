@import '../../sass/abstracts/mixins.sass'

html
    @include respondTo('size-sm')
        background: $black-alt
    
body
    overflow: hidden
    background: $black

    @include respondTo('size-sm')
        background: $black-alt
        cursor: none

*
    padding: 0
    margin: 0
    box-sizing: border-box
    -webkit-tap-highlight-color: transparent

    &:before, &:after
        box-sizing: border-box
        -webkit-tap-highlight-color: transparent
    
button, input, optgroup, select, textarea
    font-family: $primary-font

figure
    display: inline-block

ul
    li
        list-style: none
        padding: 0

img
    max-width: 100%

a
    @include respondTo('size-sm')
        cursor: none