@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.container
    @include gridContainer(33)

.isPassive
    .topTrackCaption, .topTrackSubcaption
        transform: translate(0, 100px)

        & > span
            transform: translate(0, 100%)
            opacity: 0
    
.sectionIcon
    grid-area: 3 / 3 / 4 / 4
   
.userSummary
    @include innerGridContainer(7)
    grid-area: 4 / 3 / 10 / 10
    z-index: 20
    pointer-events: none

.userSummaryContent
    @include fullAbsolute
    z-index: 20

.topTrackCaption
    @include sectionCaptionText
    transform: translate(0, 0)
    transition: 1s $secondary-ease-in .1s

    & > span
        display: inline-block
        opacity: 1
        transform: translate(0, 0)
        transition: 1s $secondary-ease-in .1s

.topTrackArtistName
    color: $green
    hyphens: auto
    word-break: break-word
    
.topTrackSubcaption
    @include sectionSubcaptionText
    transform: translate(0, 0)
    transition: 1s $secondary-ease-in .2s

    & > span
        display: inline-block
        opacity: 1
        transform: translate(0, 0)
        transition: 1s $secondary-ease-in .2s

.trackOne
    grid-area: 3 / 11 / 9 / 16

.trackTwo
    grid-area: 4 / 17 / 8 / 20

.trackThree
    grid-area: 3 / 21 / 7 / 24

.trackFour
    grid-area: 4 / 25 / 8 / 28

.trackFive
    grid-area: 2 / 29 / 6 / 32

.trackCardContainer
    @include innerGridContainer(3)

    &.featured
        @include innerGridContainer(5)

        .trackActions
            right: 30px
            top: 30px
                
            svg
                margin-bottom: 15px

        .albumCover
            height: 50vh

        .titleColumn
            width: calc(100% - 60px)
            flex-basis: calc(100% - 60px)

        .trackName
            width: calc(40vh - 45px)
            @include responsiveHeightText($lg-title-text)
            line-height: 1.28
            letter-spacing: -1px

        .artistName
            width: calc(40vh - 45px)
            @include responsiveHeightText($md-title-text)

        .audioControl
            @include scaleWidthHeight($track-card-control-dim, $track-card-control-dim)

            & > span
                width: $track-card-audio-icon-dim
                height: $track-card-audio-icon-dim

.trackCard
    @include fullAbsolute
    z-index: 20

    &.isCardPassive
        .albumCover
            clip-path: inset(0px 100% 100% 0px)

        .audioControl
            transform: scale(0)

        .titleColumn
            transform: translateY(16px)

        .trackName, .artistName
            transform: translateY(10vh)

        .topQuickInfo > div
            transform: translate(0, 30px)

            & > span
                opacity: 0
                transform: translate(0, 30px)

    &:not(.isCardPassive)
        animation: no-pointer 1.5s

        .albumCover
            animation: album-cover-in .7s $secondary-ease-in

            & > div
                animation: album-cover-image-in 1.5s $secondary-ease-in

        .audioControl
            animation: card-audio-scale-up .7s $secondary-ease-in

        .titleColumn
            animation: track-title-in .7s $secondary-ease-in .4s

        .trackName
            animation: card-text-reveal-up .8s $secondary-ease-in .4s

        .artistName
            animation: card-text-reveal-up .8s $secondary-ease-in .5s

        .topNumber
            animation: quick-info-item-in .7s $secondary-ease-in .1s

        .explicitIcon
            animation: quick-info-item-in .7s $secondary-ease-in .2s
            
    &:hover
        .topNumber
            color: $white

        .trackActions
            a > span
                opacity: 1
                transform: translate3d(0, 0, 0)
                transition: .5s $secondary-ease-in .1s

            span > svg
                opacity: 1
                transform: scale(1)
                transition: .5s $ease-out-back

            .expandCorner
                transform: translate(0, 0)
                transition: .5s $ease-out-back

        .albumCover
            &:after
                opacity: 1

            & > div
                transform: scale3d(1.07, 1.07, 1.07)
                transition: 1s $secondary-ease-in

.topQuickInfo
    position: absolute
    top: 10px
    min-width: 30px
    transform: translateX(calc(-100% - 3px))

    @include respondTo('height-md')
        transform: translateX(calc(-100% - 7px))

    @include respondTo('height-lg')
        transform: translateX(calc(-100% - 9px))

    @include respondTo('height-xl')
        transform: translateX(calc(-100% - 13px))

    & > div
        display: flex
        flex-direction: column
        align-items: flex-start
        transform: translate(0, 0)
        transition: .7s $secondary-ease-in

.topNumber
    color: $mild-gray
    @include responsiveHeightText($sm-title-text)
    line-height: 1.33
    font-weight: 300
    margin: 0 0 .2em 3px
    opacity: 1
    transform: translate(0, 0)
    transition: .5s $secondary-ease-in, transform 0s ease .1s, opacity 0s ease .1s

.explicitIcon
    opacity: 1
    transform: translate(0, 0)
    transition: 0s ease .2s

    & > svg
        transform-origin: center left
        @include scaleSvg

.trackActions
    position: absolute
    top: 20px
    right: 20px
    z-index: 11

    & > div
        @include scaleSvg
        display: flex
        flex-direction: column
        transform-origin: top right

    svg
        margin-bottom: 5px
        transition: 0s linear .5s, opacity .5s $secondary-ease-in

    span > svg
        opacity: 0
        transform: scale(0.8)

    a > span
        opacity: 0
        transform: translate(0, 20px)

.expandCorner
    transition: 0s linear .5s

    &:nth-child(1)
        transform: translate(-.5px, .5px)

    &:nth-child(2)
        transform: translate(.5px, .5px)

    &:nth-child(3)
        transform: translate(.5px, -.5px)

    &:nth-child(4)
        transform: translate(-.5px, -.5px)

.albumCover
    width: 100%
    height: 30vh
    clip-path: inset(0px 0px 0px 0px)
    position: relative

    & > div
        width: 100%
        height: 100%
        background-size: cover
        background-position: center
        transition: .5s $secondary-ease-in

    @include after
        @include fullAbsolute
        background: linear-gradient(225deg, rgba(26,27,28,0.5) 0%, rgba(26,27,28,0) 100%)
        opacity: 0
        transition: .5s $secondary-ease-in

.trackInfo
    margin-top: 28px * $h-sm-multiplier
    display: flex
    justify-content: space-between

    @include respondTo('height-md')
        margin-top: 28px * $h-md-multiplier

    @include respondTo('height-lg')
        margin-top: 28px * $h-lg-multiplier

    @include respondTo('height-xl')
        margin-top: 28px

.titleColumn
    width: calc(100% - 45px)
    flex-basis: calc(100% - 45px)
    transform: translateY(-4px)
    transition: transform 0s ease .4s
    overflow: hidden
    pointer-events: none

.audioControl
    @include scaleWidthHeight($track-card-control-dim-sm, $track-card-control-dim-sm)
    @include fullCenter
    border: $regular-border solid $green
    border-radius: 50%
    transform: scale(1)
    transition: transform 0s ease .3s

    & > span
        width: $track-card-audio-icon-dim-sm
        height: $track-card-audio-icon-dim-sm
        @include scaleSvg

.trackName
    width: calc(30vh - 45px)
    @include responsiveHeightText($large-text)
    line-height: 1.25
    letter-spacing: -0.78px
    font-family: 'Circular Std'
    font-weight: 700
    @include ellipsis
    transition: transform 0s ease .4s

.artistName
    width: calc(30vh - 45px)
    margin-top: 4px
    @include responsiveHeightText($sm-title-text)
    line-height: 1.33
    font-weight: 300
    color: $mild-gray
    @include ellipsis
    transition: transform 0s ease .5s

.whipPlaylist
    grid-area: 7 / 29 / 9 / 33

.topModalButton
    grid-area: 6 / 33 / 7 / 34
    

@keyframes album-cover-in
    0%
        transform: translateZ(0)
        clip-path: inset(0px 100% 100% 0px)

    100%
        transform: translateZ(0)
        clip-path: inset(0px 0px 0px 0px)

@keyframes album-cover-image-in
    0%
        transform-origin: top
        transform: scale3d(2.22, 2.22, 2.22)

    100%
        transform-origin: top
        transform: scale3d(1, 1, 1)

@keyframes card-audio-scale-up
    0%
        transform: scale3d(0, 0, 0)

    100%
        transform: scale3d(1, 1, 1)

@keyframes track-title-in
    0%
        transform: translate3d(0, 16px, 0)

    100%
        transform: translate3d(0, -4px, 0)

@keyframes card-text-reveal-up
    0%
        transform: translate3d(0, 10vh, 0)

    100%
        transform: translate3d(0, 0, 0)

@keyframes quick-info-item-in
    0%
        opacity: 0
        transform: translate3d(0, 30px, 0)

    100%
        opacity: 1
        transform: translate3d(0, 0, 0)

@keyframes no-pointer
    from
        pointer-events: none

    to
        pointer-events: none