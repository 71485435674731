@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.sectionIconBox
	@include innerGridContainer(1)

.sectionIconBoxContent
	@include fullAbsolute
	clip-path: inset(0 0 0 0 round $medium-border-radius)
	transition: .7s $secondary-ease-in
	z-index: 20

	&.isPassiveBox
		clip-path: inset(50% 50% round 33%)

		.boxBgIcon svg
			opacity: 0

	& > span
		display: inline-block
		width: 10vh
		height: 10vh
		position: relative
		overflow: hidden
		@include fullCenter

.glow
	position: absolute
	top: 5vh
	left: 5vh
	width: 166.67vh
	height: 166.67vh
	opacity: 0
	background: $green-glow
	transform: translate(-50%, -50%) scale(1)
	border-radius: 50%
	transition: 2s $secondary-ease-in
	pointer-events: none

.isPassiveGlow
	transform: translate(-50%, -50%) scale(0.055)
	opacity: 1

.boxIcon
	display: flex

	svg
		width: 5vh
		height: 5vh

.boxBgIcon
	position: absolute
	top: 2.8vh
	left: 2.8vh
	mix-blend-mode: overlay

	svg
		width: 14.4vh
		height: 14.4vh
		opacity: 1
		transition: 1.5s $secondary-ease-in

.micIconBox
	.boxIcon svg
		width: 5vh
		height: 10vh
		transform: translateY(20%)

.musicIconBox
	.boxBgIcon
		top: 0
		left: -1.15vh

		svg
			width: 18.89vh
			height: 18.89vh
			transform: translateY(-53%)

.discIconBox
	.boxBgIcon
		top: -2.2vh
		left: -2.2vh

.analyticsIconBox
	.boxBgIcon
		top: 1.3vh
		left: 2.2vh

.danceIconBox
	.boxBgIcon
		top: initial
		bottom: .4vh
		left: -3.8vh

		svg
			width: 16.67vh
			height: 16.67vh

.boltIconBox
	.boxBgIcon
		top: -1vh
		left: -4.44vh

		svg
			width: 18.89vh
			height: 18.89vh

.smileyIconBox
	.boxBgIcon
		top: 0
		left: 3.33vh

.handIconBox
	.boxIcon svg
		height: 8.17vh
		transform: translateY(15%)
