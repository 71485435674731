@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.header
	position: fixed
	top: 0
	right: 0
	left: 0
	z-index: 110

	@include before
		position: absolute
		top: 0
		right: 0
		left: 0
		height: $mobile-header-height
		opacity: 1
		background: linear-gradient(#111112 0%, rgba(17,17,18,0) 100%)
		pointer-events: none

	&.landingHeader
		height: $mobile-nav-height

		.nav
			padding: 5px 8vw

		.logo
			transform: translate3d(0, 60px, 0)

	&:not(.loading)
		.logo
			opacity: 1
			transform: translate3d(0, 0, 0)
			transition: .7s $secondary-ease-in 1s

		.landingMenu > li
			opacity: 1
			transform: translate(0, 0)
			transition: .7s $secondary-ease-in 1.1s

			&:nth-child(2)
				transition-delay: 1.2s

		.menu
			clip-path: inset(0% 0% 0% 0% round $regular-border-radius)
			transition: .7s $secondary-ease-in 1.1s

		.menuTitles
			transform: translate(0, 0)
			transition: .7s $secondary-ease-in 1.1s

		.userAvatar
			opacity: 1
			transition: .7s $secondary-ease-in 1.3s

	&.modalOpen
		pointer-events: none

		&:before
			opacity: 0
			transition: .7s $secondary-ease-in

		.logo, .menu, .userAvatar
			opacity: 0
			transform: translate3d(0, -60px, 0)
			transition: .7s $secondary-ease-in

		.menu
			transition-delay: .1s

		.userAvatar
			transition-delay: .2s

	&.modalClose
		&:before
			transition: .5s $secondary-ease-in .1s

		.logo, .menu, .userAvatar
			transition: .5s $secondary-ease-in

		.logo
			transition-delay: .2s

		.menu
			transition-delay: .15s

		.userAvatar
			transition-delay: .1s

.nav
	height: $mobile-nav-height
	padding: 5px 5.3vw
	display: flex
	justify-content: space-between
	align-items: center

.logo
	opacity: 0

	& > svg
		width: $mobile-header-logo-dim
		height: $mobile-header-logo-dim

.menu
	position: relative
	width: $mobile-menu-width
	height: $mobile-menu-height
	background: $dark-green-alt
	border-radius: $regular-border-radius
	clip-path: inset(0% 50% 0% 50% round $regular-border-radius)
	overflow: hidden
	transform: translate3d(0, 0, 0)

.statsProgress
	@include fullAbsolute
	background: $dark-green
	transform: scale3d(0, 1, 1)
	transform-origin: left

.menuTitles
	transform: translate3d(0, 40px, 0)

	& > div
		display: flex
		flex-direction: column
		transition: .7s $secondary-ease-in
		z-index: 10

	span
		@include fullCenter
		height: $mobile-menu-height
		font-size: $mobile-menu-text
		line-height: 1.33
		font-weight: 700

.userAvatar
	opacity: 0
	width: $avatar-dimension
	height: $avatar-dimension
	border-radius: 50%
	background-size: cover
	background-position: center
	transform: translate3d(0, 0, 0)

.landingMenu
	display: flex
	align-items: center

	li
		margin-left: 20px
		display: flex
		align-items: center
		font-size: $sm-title-text
		line-height: 1.28
		letter-spacing: -0.75px
		font-family: 'Circular Std'
		font-weight: 500
		opacity: 0
		transform: translate3d(0, 60px, 0)

		svg
			margin-right: 8px

.share
	color: $green

	path
		fill: $green