@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.audioFeatureCard
	display: inline-block
	@include scaleWidth($list-card-width)
	background: $light-gray-alt
	border-radius: $medium-border-radius
	overflow: hidden
	padding: 15px 15px 25px 10px
	display: flex
	justify-content: space-between
	align-items: center
	position: relative
	opacity: 0
	transform: translate3d(0, 40px, 0)
	transition: .7s $secondary-ease-in

	&:not(.isPassive)
		opacity: 1
		transform: translate(0, 0)

		.featureLabel, .featureValue
			transform: translate(0, 0)

		.featureProgress
			&:before
				transform: scale(1)
			
			& > div
				transform: scale(1)

	@include respondTo('height-lg')
		padding: 15px 20px 25px 15px

	@include respondTo('height-xl')
		padding: 15px 20px 25px

	svg
		transform-origin: center left
		@include scaleSvg
		position: relative
		top: 5px

	.featureSummary
		width: calc(100% - 45px)
		flex-basis: calc(100% + 5px)
		position: relative
		z-index: 10

		@include respondTo('height-lg')
			width: calc(100% - 44px)
			flex-basis: calc(100% - 44px)

		@include respondTo('height-xl')
			width: calc(100% - 50px)
			flex-basis: calc(100% - 50px)

	.featureLabel
		display: block
		@include ellipsis
		color: $mild-gray
		@include responsiveHeightText($regular-text)
		line-height: 1.3
		font-weight: 500
		transform: translate3d(0, 130px, 0)
		transition: .7s $secondary-ease-in

	.featureValue
		@include responsiveHeightText($audio-feature-card-text)
		line-height: 1.25
		font-family: 'Circular Std'
		font-weight: 700
		transform: translate3d(0, 130px, 0)
		transition: .7s $secondary-ease-in .1s

		sub
			display: inline-block
			font-size: .55em
			transform: translateY(-.3em)

.featureProgress
	position: absolute
	right: 0
	bottom: 0
	left: 0
	height: 4px
	
	@include before
		@include fullAbsolute
		background: transparentize($green, .7)
		transform: scale3d(0, 1, 1)
		transform-origin: left
		transition: .7s $secondary-ease-in

	& > div
		position: absolute
		top: 0
		bottom: 0
		left: 0
		background: $green
		transform: scale3d(0, 1, 1)
		transform-origin: left
		transition: 1s $secondary-ease-in .2s