@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.card
    position: relative
    display: flex
    align-items: center
    background: $dark-green
    border-radius: $medium-border-radius
    padding: 20px
    font-size: $sm-title-text
    line-height: 1.39
    letter-spacing: -0.56px
    font-family: "Circular Std"
    font-weight: 700
    overflow: hidden

    @include before
        position: absolute
        left: calc(((84vw - 15px) * -0.3) - 15px)
        width: calc(84vw - 15px)
        height: calc(84vw - 15px)
        background: $black
        opacity: .1
        border-radius: 50%
        z-index: 5

    @include after
        position: absolute
        left: calc(((84vw - 15px) * -0.03) - 15px)
        width: calc((84vw - 15px) * 0.47)
        height: calc((84vw - 15px) * 0.47)
        background: $black
        opacity: .2
        border-radius: 50%
        z-index: 10

    span
        z-index: 15

.musicIcon
    display: inline-flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 52px
    height: 52px
    margin-right: 20px
    background: $black
    border-radius: 50%

    & > svg
        width: 24px
        height: 24px

        path
            fill: $green