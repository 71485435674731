body
    font-family: $primary-font
    -webkit-font-smoothing: antialiased
    font-size: 1rem
    color: $white  
p
    font-size: $p-text
    font-weight: 300

h1, h2, h3
    font-weight: 600

h4, h5, h6
    font-weight: 500

a
    text-decoration: none
    color: inherit

    @include hoverFocusActiveState
        text-decoration: none
        color: inherit

small
    font-size: $small-text

strong
    font-weight: 500