@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.container
    @include gridContainer(26)

.sectionIcon
    grid-area: 3 / 3 / 4 / 4

.userSummary
    @include innerGridContainer(7)
    grid-area: 4 / 3 / 10 / 10
    z-index: 20
    pointer-events: none
    
.userSummaryContent
    @include fullAbsolute
    z-index: 20

.topArtistCaption
    @include sectionCaptionText
    transform: translate(0, 100px)
    animation: swipe-up 1s $secondary-ease-in 1.1s forwards
    
    & > span
        display: inline-block
        padding-right: 5vh
        transform: translate(0, 100%)
        opacity: 0
        animation: swipe-up 1s $secondary-ease-in 1.1s forwards

.topArtistName
    color: $green
    hyphens: auto
    word-break: break-word
    
.topArtistSubcaption
    @include sectionSubcaptionText
    transform: translate(0, 100px)
    animation: swipe-up 1s $secondary-ease-in 1.2s forwards

    & > span
        display: inline-block
        transform: translate(0, 100%)
        opacity: 0
        animation: swipe-up 1s $secondary-ease-in 1.2s forwards

.artistOne
    grid-area: 3 / 10 / 8 / 14

.artistTwo
    grid-area: 4 / 15 / 7 / 18

.artistThree
    grid-area: 7 / 18 / 10 / 21

.artistFour
    grid-area: 3 / 19 / 6 / 22

.artistFive
    grid-area: 2 / 23 / 5 / 26

.artistCardContainer
    @include innerGridContainer(3)
    z-index: 10
    transition: .8s
    
    &.cardActive
        transition: 0s
        z-index: 8

    &.featured
        @include innerGridContainer(4)

        .artistCard
            padding: 20px 30px

            &.isCardPassive
                padding: 0

            &:hover
                .artistCover:not(.trackPlayerActive)
                    transform: scale3d(1.07, 1.07, 1.07)
            
            h3
                @include responsiveHeightText($sm-title-text)
                line-height: 1.33

        .artistCover
            width: 40vh
            height: 50vh

        .artistName
            @include responsiveHeightText($lg-title-text)
            line-height: 1.28
            letter-spacing: -1px

        .titleColumn
            min-width: calc(40vh - 116px)
            width: calc(100% - 56px)
            flex-basis: calc(100% - 56px)

        .value
            @include responsiveHeightText($large-text)
            line-height: 1.25

        .trackActions, .artistActions
            right: 28px

        .trackActions
            bottom: 74px

            @include respondTo('height-md')
                bottom: 80px

            @include respondTo('height-lg')
                bottom: 84px

            @include respondTo('height-xl')
                bottom: 94px

            & > div > *
                margin-top: 2px

            svg
                margin-bottom: 5px
                
        .artistActions
            top: 30px

            svg
                margin-bottom: 10px

        .artistInfo
            top: 30px
            left: 30px

        .trackCoverBg
            left: calc(100% - #{22px + ($artist-card-control-dim * $h-sm-multiplier)})
            transform-origin: 49% 56%

            @include respondTo('height-md')
                left: calc(100% - #{22px + ($artist-card-control-dim * $h-md-multiplier)})

            @include respondTo('height-lg')
                left: calc(100% - #{22px + ($artist-card-control-dim * $h-lg-multiplier)})

            @include respondTo('height-xl')
                left: calc(100% - #{22px + $artist-card-control-dim})

            &.active
                transform: scale3d(11.74, 11.74, 11.74)

        .trackDetails
            right: 60px
            bottom: 15px
            width: 17.5vh

            @include respondTo('height-md')
                right: 66px

            @include respondTo('height-lg')
                right: 69px

            @include respondTo('height-xl')
                right: 72px

        .trackCover
            width: 14.4vh
            height: 14.4vh

        .trackProgress
            width: 14.4vh
            margin-top: 10px

        .topTrackName
            margin: 15px 0 0
            @include responsiveHeightText($artist-card-track-name)

            & > span
                height: 2.5em

.artistCard
    @include fullAbsolute
    z-index: 20
    border-radius: $small-border-radius
    display: flex
    justify-content: space-between
    align-items: flex-end
    clip-path: inset(0px 0px 0px 0px round $small-border-radius)
    padding: 20px
    overflow: hidden

    &.isCardPassive
        clip-path: inset(0px 100% 100% 0px round $small-border-radius)
        padding: 0

    &:not(.isCardPassive)
        animation: artist-card-in 1.5s $secondary-ease-in

        &:before
            animation: fade-in .7s $secondary-ease-in

        .titleColumn
            animation: title-column-in 1.1s

            h3
                animation: artist-title-in 1.2s

        .artistCover
            animation: artist-cover-in 1.5s $secondary-ease-in

        .artistName
            animation: artist-name-in 1.4s

        .trackCoverBg
            animation: track-cover-bg-in 1.1s

    @include before
        @include fullAbsolute
        background: linear-gradient(204.29deg, rgba(17,17,18,0) 0%, rgba(17,17,18,0.7) 100%)
        z-index: 4
        opacity: 1
        transition: .5s $primary-ease

    @include after
        @include fullAbsolute
        background: linear-gradient(45.94deg, rgba(26,27,28,0.6) 0%, rgba(26,27,28,0.6) 99%)
        z-index: 4
        opacity: 0
        transition: .5s $primary-ease

    &:hover
        &:before
            opacity: 0

        &:after
            transform: translateZ(0)
            opacity: 1

        .artistCover
            &:not(.trackPlayerActive)
                transform: scale3d(1.1, 1.1, 1.1)
                transition: .5s $primary-ease

                &.cardIn
                    transition: 1s $secondary-ease-in

            .trackPlayerActive
                transition: .5s $primary-ease

        .artistInfo
            li
                transform: translate3d(0, 0, 0)
                transition: .4s $primary-ease

                &:nth-child(1)
                    transition-delay: .1s

                    h3
                        transition-delay: .1s

                    .value
                        transition-delay: .15s

                &:nth-child(2)
                    transition-delay: .2s

                    h3
                        transition-delay: .2s

                    .value
                        transition-delay: .25s

                &:nth-child(3)
                    transition-delay: .3s

                    h3
                        transition-delay: .3s

                    .value
                        transition-delay: .35s

            h3, .value
                transform: translate3d(0, 0, 0)
                transition: .4s $primary-ease

        .artistActions
            svg, a > span
                opacity: 1
                transform: translate3d(0, 0, 0)
                transition: .5s $primary-ease

                &:nth-child(1)
                    transition-delay: .2s
                
                &:nth-child(2)
                    transition-delay: .4s

        .titleColumn
            transform: translateZ(0)

    h3
        @include responsiveHeightText($regular-text)
        line-height: 1.63
        font-weight: 400
        margin-bottom: 0

.artistCover
    position: absolute
    top: 0
    left: 0
    object-fit: cover
    object-position: center
    width: 30vh
    height: 30vh
    transform: scale(1)
    transform-origin: bottom right
    z-index: -2
    transition: .5s $ease-both

.artistName
    display: block
    @include responsiveHeightText($large-text)
    line-height: 1.25
    letter-spacing: -0.78px
    font-family: 'Circular Std'
    font-weight: 700
    transition: .2s ease

    & > span
        display: flex
        flex-direction: column

.titleColumn
    min-width: calc(30vh - 85px)
    width: calc(100% - 45px)
    flex-basis: calc(100% - 45px)
    z-index: 10

.audioControl
    display: block
    transform: translateX(8px)
    z-index: 20

    .audioToggle
        @include scaleWidthHeight($artist-card-control-dim, $artist-card-control-dim)
        border-radius: 50%
        @include fullCenter

        &.withBg
            background: $black

        &.playerActive
            svg > g > g
                &:nth-child(1), &:nth-child(3)
                    path
                        fill: $white

                &:nth-child(2), &:nth-child(4)
                    path
                        stroke: $white

        & > span
            width: $artist-card-audio-icon-dim
            height: $artist-card-audio-icon-dim
            @include scaleSvg

        svg > g > g
            &:nth-child(1), &:nth-child(3)
                path
                    transition: fill .3s $primary-ease

            &:nth-child(2), &:nth-child(4)
                path
                    transition: stroke .3s $primary-ease

.trackCoverBg
    position: absolute
    @include scaleWidthHeight($artist-card-control-dim, $artist-card-control-dim)
    border-radius: 50%
    z-index: 15
    transition: .5s $ease-both, opacity 0s
    transform: scale(1)
    top: calc(100% - #{20px + ($artist-card-control-dim * $h-sm-multiplier)})
    left: calc(100% - #{12px + ($artist-card-control-dim * $h-sm-multiplier)})

    @include respondTo('height-md')
        top: calc(100% - #{20px + ($artist-card-control-dim * $h-md-multiplier)})
        left: calc(100% - #{12px + ($artist-card-control-dim * $h-md-multiplier)})

    @include respondTo('height-lg')
        top: calc(100% - #{20px + ($artist-card-control-dim * $h-lg-multiplier)})
        left: calc(100% - #{12px + ($artist-card-control-dim * $h-lg-multiplier)})

    @include respondTo('height-xl')
        top: calc(100% - #{20px + $artist-card-control-dim})
        left: calc(100% - #{12px + $artist-card-control-dim})

    &.active
        transform: scale3d(9.5, 9.5, 9.5)
        transition: .3s $primary-ease

.trackPlayerBackdrop
    @include fullAbsolute
    background: linear-gradient(69.29deg, rgba(26,27,28,0.7) 0%, rgba(26,27,28,0.7) 100%)
    z-index: 12
    opacity: 0
    transition: .5s $ease-both
    pointer-events: none

    &.active
        opacity: 1
        transition: .5s $primary-ease

.trackDetails
    position: absolute
    right: 50px
    bottom: 18px
    width: 15vh
    display: flex
    flex-direction: column
    align-items: flex-end
    z-index: 18
    pointer-events: none

    @include respondTo('height-md')
        right: 56px

    @include respondTo('height-lg')
        right: 59px

    @include respondTo('height-xl')
        right: 62px

    &.active
        .playerData
            transform: translate(0, 0)
            transition: .5s $ease-both

        .trackCover, .trackProgress
            opacity: 1
            transform: translate(0, 0)
            transition: .5s $ease-both

        .topTrackName
            transform: translate(0, 0)
            transition: .5s $ease-both

            & > span
                transform: translate(0, 0)
                transition: .5s $ease-both

.playerData
    transform: translate(0, 15px)
    transition: .3s $ease-both

.trackCover
    width: 10vh
    height: 10vh
    background-size: cover
    transform: translate(0, 20px)
    opacity: 0
    transition: .3s $ease-both

.trackProgress
    width: 10vh
    height: 3px
    margin-top: 8px
    background: transparentize($white, .7)
    transform: translate(0, 10px)
    opacity: 0
    position: relative
    border-radius: $x-large-border-radius
    overflow: hidden
    transition: .3s $ease-both

    & > div
        @include fullAbsolute
        transform-origin: left
        transform: scaleX(0)
        background: $white
        border-radius: $x-large-border-radius
        transition: .6s linear

        &.progressEnd
            transition: none

.topTrackName
    margin: 10px 0 0
    width: 100%
    flex-basis: 100%
    @include responsiveHeightText($artist-card-track-name-sm)
    font-family: 'Circular Std'
    font-weight: 700
    letter-spacing: -0.7px
    line-height: 1.25
    text-align: right
    overflow: hidden
    transform: translate(0, 10px)
    transition: .3s $ease-both

    & > span
        height: 2.4em
        display: flex
        flex-direction: column
        justify-content: center
        transform: translate(0, 100%)
        transition: .3s $ease-both

.trackActions, .artistActions
    position: absolute
    right: 19px

    & > div
        @include scaleSvg
        display: flex
        flex-direction: column

.trackActions
    bottom: 65px
    z-index: 18
    pointer-events: none
    transform: translate(0, 15px)
    transition: transform .5s $ease-both

    @include respondTo('height-md')
        bottom: 75px

    @include respondTo('height-lg')
        bottom: 80px

    @include respondTo('height-xl')
        bottom: 88px

    &.active
        pointer-events: all
        transform: translate3d(0, 0, 0)
        transition: transform .5s $ease-both

        svg
            opacity: 1
            transition: .5s $ease-both
            transform: translate3d(0, 0, 0)

    & > div
        transform-origin: bottom right

        span svg
            transform: scale(.8)
            transform-origin: top center

        & > *
            margin-top: 5px

    svg
        opacity: 0
        transform: translate(0, 10px)
        transition: .3s $ease-both

.artistActions
    top: 20px
    z-index: 11

    & > div
        transform-origin: top right

    svg
        margin-bottom: 12px
        opacity: 0
        transform: translate(0, 20px)
        transition: .5s $ease-both

    a > span
        transform: translate(0, 20px)
        transition: .5s $ease-both

.artistInfo
    position: absolute
    top: 20px
    right: 70px
    left: 20px
    transition: .5s ease
    z-index: 10

    li
        margin-bottom: 15px
        transform: translate(0, 20px)
        overflow: hidden
        transition: .5s $ease-both

        h3
            transform: translate(0, 500%)
            transition: .5s $ease-both

.value
    @include responsiveHeightText($large-text - 4)
    line-height: 1.5
    letter-spacing: -0.78px
    font-family: 'Circular Std'
    font-weight: 700
    transform: translate(0, 250%)
    transition: .5s $ease-both
    display: flex
    flex-direction: column

.whipPlaylist
    grid-area: 7 / 22 / 9 / 26

.topModalButton
    grid-area: 5 / 26 / 6 / 27

.cardGlow
    position: absolute
    top: -10vh
    right: -10vh
    bottom: -10vh
    left: -10vh
    background: $white-glow, $white-glow, $white-glow, $white-glow 
    background-repeat: no-repeat
    background-size: 20vh 20vh, 20vh 20vh, 20vh 20vh, 20vh 20vh
    background-position: top left, top right, bottom left, bottom right
    opacity: 0
    transition: .5s $ease-both
    pointer-events: none

.cardGlowActive
    opacity: 1
    transition: .5s $primary-ease

@keyframes title-column-in
    0%
        transform: translate3d(0, 11.1vh, 0)

    36%
        animation-timing-function: $secondary-ease-in
        transform: translate3d(0, 11.1vh, 0)

    100%
        transform: translate3d(0, 0, 0)

@keyframes artist-card-in
    0%
        pointer-events: none
        animation-timing-function: $secondary-ease-in
        transform: translateZ(0)
        clip-path: inset(0px 100% 100% 0px round $small-border-radius)

    47%
        transform: translateZ(0)
        clip-path: inset(0px 0px 0px 0px round $small-border-radius)

    100%
        pointer-events: none

@keyframes artist-cover-in
    0%
        transform-origin: top
        transform: scale3d(2.22, 2.22, 2.22)

    100%
        transform-origin: top
        transform: scale3d(1, 1, 1)

@keyframes artist-title-in
    0%
        transform: translate3d(0, 18.8vh, 0)

    33%
        animation-timing-function: $secondary-ease-in
        transform: translate3d(0, 18.8vh, 0)

    100%
        transform: translate3d(0, 0, 0)

@keyframes artist-name-in
    0%
        transform: translate3d(0, 18.8vh, 0)

    43%
        animation-timing-function: $secondary-ease-in
        transform: translate3d(0, 18.8vh, 0)

    100%
        transform: translate3d(0, 0, 0)

@keyframes track-cover-bg-in
    0%
        transform: scale3d(8.7, 8.7, 8.7)

    18%
        animation-timing-function: $secondary-ease-in
        transform: scale3d(8.7, 8.7, 8.7)

    100%
        transform: scale3d(1, 1, 1)
        
@keyframes fade-in
    from
        opacity: 0

    to
        opacity: 1

@keyframes swipe-up
    to
        opacity: 1
        transform: translate(0, 0)

@keyframes no-pointer
    from
        pointer-events: none

    to
        pointer-events: none