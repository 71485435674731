@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.artistNav
	@include mobileModalContentNav

.navActions
	display: flex
	align-items: center

	a
		display: inline-flex
		align-items: center
		font-size: $regular-text
		font-family: 'Circular Std'
		line-height: 1.25
		letter-spacing: -0.67px
		font-weight: 500
		opacity: 1
		transform: translate3d(0, 0, 0)
		transition: .7s $secondary-ease-in .35s

		svg
			margin-right: 8px

.hideNav
	a
		opacity: 0
		transform: translate3d(0, 60px, 0)
		transition: 0s

.closeNav
	@extend .hideNav

	a
		transition: .5s $secondary-ease-in .05s

.artistCover
	position: absolute
	top: 0
	right: 0
	left: 0
	height: 100vw
	min-height: 375px
	clip-path: inset(0 0 0 0)
	overflow: hidden
	z-index: -1

	@include after
		position: absolute
		top: 0
		right: 0
		bottom: -2px
		left: 0
		background: linear-gradient(rgba(25,25,26,0) 0%, #19191A 100%)

.coverImage
	width: 100vw
	height: 100vw
	min-height: 375px
	background-size: cover
	background-position: center
	opacity: 0
	transform: scale(2.66)
	animation: fade-cover-in 1.4s $secondary-ease-in forwards
	will-change: opacity, transform

.artistSummary
	position: relative
	height: calc(100vw + 25px)
	min-height: 400px
	padding: 30px 8vw 0
	display: flex
	flex-direction: column
	justify-content: flex-end
	z-index: 5

.title
	margin: 0 0 10px
	text-align: center
	font-size: $mobile-modal-title-text
	font-family: 'Circular Std'
	line-height: 1.17
	letter-spacing: -2.88px
	font-weight: 900
	opacity: 0
	transform: translate3d(0, 200px, 0)
	animation: swipe-up .7s $secondary-ease-in .3s forwards

	& > span
		display: flex
		flex-direction: column

.artistGenres
	text-align: center

	span
		height: $mobile-pill-height
		background: transparentize($white, .9)
		border-radius: $regular-border-radius
		display: inline-flex
		align-items: center
		padding: 0 20px
		font-size: $regular-text
		line-height: 1.5
		font-weight: 500
		opacity: 0
		transform: translate3d(0, 200px, 0)
		animation: swipe-up .7s $secondary-ease-in .4s forwards

		&:nth-child(2)
			margin-left: 6px
			background: $white
			color: $black
			transition-delay: .45s

.playlistButton
	margin-top: 20px
	height: $modal-btn-height
	background: $mild-green
	font-size: $sm-title-text
	line-height: 1.33
	opacity: 0
	transform: translate3d(0, 200px, 0)
	animation: swipe-up .7s $secondary-ease-in .5s forwards

	svg
		transform: scale(1)

.quickStats
	margin: 30px 0 40px

	nav
		display: flex
		justify-content: center

@keyframes fade-cover-in
	to
		opacity: 1
		transform: scale(1)

@keyframes swipe-up
	to
		opacity: 1
		transform: translate3d(0, 0, 0)
