@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.shareCard
    @include scaleHeight($share-card-height)
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    background: $dark-ash
    padding: 18% 2px 14%
    border-radius: $medium-border-radius
    @include responsiveHeightText($sm-title-text)
    line-height: 1.67
    letter-spacing: -0.79px
    font-family: 'Circular Std'

    svg
        @include scaleWidthHeight(50px, 50px)