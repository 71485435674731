@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.container
	margin-top: $mobile-nav-height + 20
	padding: 0 8vw

.userSummary
	margin: 10px 0

.topArtistCaption
	@include mobileSectionCaptionText

	& > span
		display: inline-block
		opacity: 0
		transform: translate(0, 200px)
		animation: swipe-up .7s $secondary-ease-in 1s forwards

		&:last-child
			animation-delay: 1.1s

.topArtistName
	color: $green

.topArtistSubcaption
	@include mobileSectionSubcaptionText

	& > span
		display: inline-block
		opacity: 0
		transform: translate(0, 200px)
		animation: swipe-up .7s $secondary-ease-in 1.2s forwards

.artistsGrid
	display: grid
	grid-template-columns: auto auto
	grid-gap: 15px
	margin-bottom: 15px

.listActions
	margin-bottom: 10px
	display: grid
	grid-template-columns: calc((100% - 15px) * 0.3) calc((100% - 15px) * 0.7)
	grid-gap: 15px

@keyframes swipe-up
	to
		opacity: 1
		transform: translate(0, 0)
