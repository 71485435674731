@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.trackPill
	position: relative
	width: 50vh
	height: 10vh
	display: flex
	align-items: center
	margin: 0 15px 15px 0
	overflow: hidden

	@include before
		@include fullAbsolute
		background: $dark-ash
		border-radius: $medium-border-radius
		opacity: 1
		transition: .7s $secondary-ease-in .7s
		z-index: -1

	a
		display: inline-flex

		svg
			transform-origin: center right
			@include scaleSvg

.isPassivePill
	&:before
		opacity: 0

	.audioPreview
		opacity: 0

	.albumCoverImage
		clip-path: inset(0px 0px 100% 0px)

		& > div
			transform: scale(3)

	.trackDetails
		transform: translate(0, 184%)

	.actions
		& > span
			opacity: 0
			transform: translate(0, 100%)

		& > a
			opacity: 0
			transform: translate(0, 100%)

.audioPreview
	width: 6.6vh
	flex-basis: 6.6vh
	flex-shrink: 0
	@include fullCenter
	opacity: 1
	transition: .7s $secondary-ease-in 1s

.audioToggle
	@include scaleWidthHeight($track-pill-control-dim, $track-pill-control-dim)
	border-radius: 50%
	@include fullCenter
	border: $regular-border solid $green

	& > span
		@include scaleWidthHeight($track-pill-audio-icon-dim, $track-pill-audio-icon-dim)
		@include fullCenter

.albumCoverImage
	width: 10vh
	flex-basis: 10vh
	flex-shrink: 0
	height: 10vh
	clip-path: inset(0px 0px 0px 0px)
	transition: .7s $secondary-ease-in .6s

	& > div
		width: 100%
		height: 100%
		background-size: cover
		background-position: center
		transform: scale(1)
		transition: 1s $secondary-ease-in .6s

.trackDetails
	width: calc(33.4vh - 95px)
	flex-basis: calc(33.4vh - 95px)
	padding: 0 20px
	overflow: hidden
	transform: translate(0, 0)
	transition: .7s $secondary-ease-in .8s

	h3
		padding: 0 10px 5px 0
		color: $white
		@include responsiveHeightText($track-pill-title-text)
		line-height: 1.27
		font-family: 'Circular Std'
		font-weight: 700
		@include ellipsis

	span
		display: block
		padding-right: 10px 
		color: $mild-gray
		@include responsiveHeightText($regular-text)
		line-height: 1.31
		@include ellipsis

.actions
	width: 95px
	flex-basis: 95px
	padding: 0 20px 0 5px
	display: inline-flex
	justify-content: flex-end
	align-items: center

	& > span
		display: inline-flex
		margin-right: 15px
		opacity: 1
		transform: translate(0, 0)
		transition: .7s $secondary-ease-in 1s

		svg
			@include scaleWidthHeight(30px, 30px)

	& > a
		opacity: 1
		transform: translate(0, 0)
		transition: .7s $secondary-ease-in 1.1s

		& > span
			@include scaleWidthHeight(30px, 30px)
