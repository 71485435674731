@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.container
	@include gridContainer(18)

.insightBlock
	@include innerGridContainer(5)

.insightBlockContent
	@include fullAbsolute
	display: grid
	grid-template-rows: repeat(5, 10vh)
	z-index: 20

.isPassive
	.insightLabel > h1
		transform: translate(0, 142%)

		& span
			transform: translate(0, 100%)
			opacity: 0

	.insightDescription
		transform: translate(0, 111.11%)

		& span
			transform: translate(0, 180%)
			opacity: 0

.insightLabel
	grid-row: 1 / 3
	pointer-events: none

	h1
		margin: 0
		@include responsiveHeightText($insight-label-text)
		line-height: 1.14
		letter-spacing: -3.89px
		font-family: 'Circular Std'
		font-weight: 700
		overflow: hidden
		transform: translate(0, 0)
		transition: 1s $secondary-ease-in .1s

		& > span
			display: inline-block
			opacity: 1
			transform: translate(0, 0)
			transition: 1s $secondary-ease-in .1s

		&:nth-child(1)
			margin-top: 20px
			transition-delay: .2s

			& > span
				transition-delay: .2s

		&:nth-child(2)
			color: $green
			transition-delay: .2s

			& > span
				transition-delay: .2s

.insightDescription
	display: flex
	align-items: center
	color: $mild-gray
	@include responsiveHeightText($insight-description-text)
	line-height: 2.04
	pointer-events: none
	overflow: hidden
	transform: translate(0, 0)
	transition: 1s $secondary-ease-in .3s

	& > span
		display: inline-block
		opacity: 1
		transform: translate(0, 0)
		transition: 1s $secondary-ease-in .3s

.insightIcon
	width: 10vh
	height: 10vh

.percentageValue
	color: $green

.insightBlockOne
	grid-area: 4 / 2 / 8 / 7

.insightBlockTwo
	grid-area: 4 / 8 / 8 / 13

.insightBlockThree
	grid-area: 4 / 14 / 8 / 19

.iconBoxOne
	grid-area: 3 / 2 / 4 / 3

.iconBoxTwo
	grid-area: 3 / 8 / 4 / 9

.iconBoxThree
	grid-area: 3 / 14 / 4 / 15
