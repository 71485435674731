//colors
$white              : #FFFFFF
$black              : #111112
$black-alt          : #161617
$pure-black         : #000000
$gray               : #222425
$gray-alt           : #C8C8C8
$dark-gray          : #161718
$dark-gray-alt      : #1A1B1C
$light-gray         : #36393A
$light-gray-alt     : #2C2F30
$mild-gray          : #7A8185
$mild-gray-alt      : #424548
$ash                : #959A9D
$ash-alt            : #8A8A8A
$mild-ash           : #ACB4B9
$dark-ash           : #252527
$dark-ash-alt       : #3B3B40
$mild-dark-ash      : #202022
$mild-dark-ash-alt  : #19191A
$green              : #33FF7A
$green-alt          : #1ED760
$mild-green         : #1DB954
$dark-green         : #189A46
$dark-green-alt     : #15843C
$deep-green         : #0E5828
$deep-green-alt     : #1B3023
$red                : #FF3660
$white-glow         : radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.1) 40%, rgba(255,255,255,0) 70%, rgba(255,255,255,0)100%)
$white-glow-alt     : radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.1) 25%, rgba(255,255,255,0) 70%)
$green-glow         : radial-gradient(circle, rgba(51,255,122,0) 0%, #33FF7A 25%, rgba(51,255,122,0) 70%)


$primary-font: 'Maison Neue', system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif

// Font sizes
$small-text         	               : 12px
$medium-text        	               : 14px
$regular-text			                   : 16px
$btn-text                            : 24px
$btn-text-md                         : 20px
$login-btn-text                      : 18px
$p-text                              : 26px
$sm-title-text			                 : 18px
$md-title-text			                 : 20px
$lg-title-text			                 : 36px
$explore-playlist-title-text         : 30px
$section-title-text			             : 22px
$large-text                          : 28px
$x-large-text                        : 40px
$xl-large-text                       : 50px
$caption-text                        : 90px
$sub-caption-text                    : 24px
$md-caption-text                     : 90px
$section-caption-text                : 90px
$team-caption-text                   : 170px
$section-subcaption-text             : 24px
$mobile-section-caption-text         : 45px
$taste-caption-text                  : 130px
$greeting-text                       : 32px
$bar-level-text                      : 22px
$mobile-bar-level-text               : 20px
$bar-name-text                       : 22px
$mobile-bar-name-text                : 20px
$artist-card-track-name              : 24px
$artist-card-track-name-sm           : 22px
$modal-title-text                    : 80px
$mobile-modal-title-text             : 60px
$mobile-modal-title-text-sm          : 40px
$modal-description-text              : 24px
$title-card-text                     : 20px
$mobile-quick-stats-text             : 24px
$carousel-title-text                 : 30px
$mobile-modal-section-title-text     : 24px
$spider-chart-tip-dim                : 245px
$spider-chart-label-text             : 20px
$spider-chart-value-text             : 72px
$audio-feature-card-text             : 32px
$mobile-audio-feature-card-text      : 45px
$insight-label-text                  : 70px
$insight-description-text            : 22px
$track-pill-title-text               : 22px
$mobile-menu-text                    : 15px
$mobile-modal-form-title-text        : 30px
$mobile-card-name-text               : 30px
$mobile-card-name-text-sm            : 22px
$playlist-track-name-text            : 22px

$cursor-dimension                   : 16px
$loading-radial-dimension           : 290px
$scrollbarWidth                     : 3px
$insufficient-data-ghost-dim        : 292px
$explore-icon-dim                   : 40px
$explore-playlist-cover-dim         : 120px
$explore-playlist-title-width       : 175px
$loader-width                       : 300px
$loader-height                      : 2px
$menu-width                         : 190px
$menu-height                        : 40px
$about-menu-width                   : 630px
$share-menu-width                   : 460px
$user-menu-width                    : 335px
$share-card-height                  : 130px
$resource-card-height               : 74px
$switch-width                       : 45px
$switch-height                      : 26px
$switch-knob-dim                    : 18px
$mobile-header-height               : 120px
$mobile-nav-height                  : 80px
$mobile-header-logo-dim             : 34px
$mobile-menu-width                  : 180px
$mobile-menu-height                 : 36px
$avatar-dimension                   : 40px
$mobile-avatar-dimension            : 36px
$mobile-login-button-min-width      : 240px
$mobile-list-action-height          : 110px
$artist-bubbles-container-x         : 515px
$artist-bubbles-height              : 310px
$artist-bubble-dim                  : 150px
$artist-bubble-popularity-dim       : 44px
$dot-dimension                      : 4px
$list-card-width                    : 200px
$list-card-height                   : 280px
$nav-progress-height                : 3px
$stats-footer-icon-dimension        : 44px 
$expl-artist-card-control-dim       : 60px
$artist-card-control-dim            : 46px
$track-card-control-dim             : 46px
$track-card-control-dim-sm          : 38px
$track-pill-control-dim             : 30px
$expl-artist-card-audio-icon-dim    : 35px
$artist-card-audio-icon-dim         : 30px
$track-card-audio-icon-dim          : 30px
$track-card-audio-icon-dim-sm       : 22px
$track-pill-audio-icon-dim          : 15px
$spider-chart-tip-bg-icon-dim       : 825px
$mobile-genre-bar-height            : 60px
$modal-main-cover-dim               : 360px
$modal-btn-height                   : 70px
$top-list-nav-label-width           : 140px 
$mobile-pill-height                 : 40px
$artist-pill-cover-dim              : 60px
$taste-profile-artist-cover-dim     : 54px
$modal-action-height                : 40px
$radial-icon-button-dim             : 56px
$mobile-track-details-album-dim     : 210px
$mobile-title-card-height           : 115px
$mobile-audio-feature-card-height   : 190px
$suggestion-playlist-button-width   : 300px
$expl-title-card-width              : 225px
$expl-carousel-chevron-ring-dim     : 40px
$expl-carousel-control-artist-dim   : 50px
$playlist-track-card-height         : 90px
$playlist-track-audio-icon-dim      : 15px
$playlist-progress-error-height     : 100px
$playlist-progress-error-width      : 360px
$card-radio-height                  : 130px
$mobile-card-radio-height           : 110px
$select-input-height                : 60px
$tab-switch-height                  : 60px
$mobile-streaming-tab-height        : 375px

// Breakpoints
$size-xs        : 350px
$size-sm        : 576px
$size-md        : 768px
$size-lg        : 992px
$size-xl        : 1200px
$size-retina    : 1600px

$height-md      : 670px
$height-lg      : 750px
$height-xl      : 900px

$h-sm-multiplier    : .68
$h-md-multiplier    : .77
$h-lg-multiplier    : .85
$h-xl-multiplier    : 1


// Bezier curvers
$primary-ease             : cubic-bezier(0.4, 0.2, 0.3, 1)
$secondary-ease-in        : cubic-bezier(0.2, 0.6, 0.35, 1)
$secondary-ease-out       : cubic-bezier(0.55, 0.05, 0.67, 0.19)
$ease-out                 : cubic-bezier(0.5, 0.1, 0.8, 0.3)
$ease-both                : cubic-bezier(0.64, 0.04, 0.35, 1)
$ease-out-back            : cubic-bezier(0.2, 1.3, 0.6, 1.6)
$pop                      : cubic-bezier(0.2, 1.3, 0.6, 3)
$pop-alt                  : cubic-bezier(0.2, 1.3, 0.6, 2)
$bounce                   : cubic-bezier(0.1, 0, 0, 1.5)
$mouse-ease               : cubic-bezier(0.05, 0.8, 0.4, 1)


$regular-border             : 2px
$md-border                  : 4px
$lg-border                  : 8px
$xl-border                  : 12px
$small-border-radius        : 10px
$medium-border-radius       : 12px
$regular-border-radius      : 20px
$large-border-radius        : 40px
$x-large-border-radius      : 50px