@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.loader
    @include fullAbsolute
    @include gridContainer(17)

.loaderContent
    @include fullAbsolute
    @include fullCenter

    @include after
        position: fixed
        top: 0
        right: 0
        bottom: 0
        left: 0
        background: $black
        z-index: 8

.radialProgress
    position: relative
    z-index: 130
    @include scaleWidthHeight($loading-radial-dimension, $loading-radial-dimension)
    @include fullCenter

    & > svg
        @include scaleWidthHeight($loading-radial-dimension, $loading-radial-dimension)

        circle:last-child
            transition: .7s $primary-ease
            animation: loader-progress-animation .5s $primary-ease
            will-change: transform

.playIcon
    position: absolute
    z-index: 130
    @include scaleWidthHeight(100px, 100px)
    @include fullCenter

    svg
        @include scaleWidthHeight(36px, 36px)
        transform: scale3d(3, 3, 3)
        opacity: 0

        path
            fill: $green

.playIconReady
    svg
        opacity: 1
        transform: scale3d(1, 1, 1)
        transition: 1s $primary-ease

.glow
    position: absolute
    top: 50%
    left: 50%
    width: 166.67vh
    height: 166.67vh
    opacity: 0
    background: $green-glow
    transform: translate(-50%, -50%) scale(1)
    border-radius: 50%
    transition: 1s $secondary-ease-in
    z-index: 10
    pointer-events: none

.isPassiveGlow
    transform: translate(-50%, -50%) scale(0.055)
    opacity: 1

@keyframes loader-progress-animation
    from
        stroke-dasharray: 0, 900