@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.cursor
    display: none
    position: fixed
    top: calc(#{-$cursor-dimension} / 2)
    left: calc(#{-$cursor-dimension} / 2)
    width: $cursor-dimension
    height: $cursor-dimension
    pointer-events: none
    z-index: 9999

    @include respondTo('size-sm')
        display: block

    &.hover
        &:before
            background: transparentize($white ,.7)
            border-color: $white
            transform: scale(1)
            transition: .3s linear

        &.focus:before
            background: transparentize($white ,.4)
            transition: .1s linear

    @include before
        @include fullAbsolute
        background: $white
        border: $regular-border solid transparent
        border-radius: 50%
        box-shadow: 0 0 25px 0 rgba(0,0,0,0.5)
        transform: scale(.5)
        transition: .2s linear

.cursorRing
    display: none
    position: fixed
    top: -5vh
    left: -5vh
    width: 10vh
    height: 10vh
    opacity: 0.5
    transition: .8s $mouse-ease
    pointer-events: none
    z-index: 9999

    @include respondTo('size-sm')
        display: block

    &.hover:before
        transform: scale(0)
        transition: .3s linear

    @include before
        @include fullAbsolute
        border: thin solid $white
        border-radius: 50%
        transform: scale(1)
        transition: .2s linear
