@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.card
    position: relative
    @include scaleWidthHeight($list-card-width, $list-card-height)
    border-radius: $medium-border-radius
    overflow: hidden
    opacity: 0
    transform: translate3d(0, 40px, 0)
    transition: .7s $secondary-ease-in

    &:not(.isPassive)
        opacity: 1
        transform: translate(0, 0)
        animation: no-pointer 1s

        .cover
            transform: scale(1)
            animation: cover-scale-down 1s $secondary-ease-in

    &.track
        border-radius: 0 0 $medium-border-radius $medium-border-radius

        &:hover
            .cardInfo
                p
                    transition-delay: .1s

                .cardActions
                    & > span
                        transition-delay: .2s

                    a
                        transition-delay: .25s

    &:hover
        .cardInfo
            max-height: 100%
            transform: translate3d(0, 0, 0)
            transition: .5s $secondary-ease-in

            h3
                min-height: 1.25em
                transition: min-height .5s $secondary-ease-in

            p
                opacity: 1
                transform: translate3d(0, 0, 0)
                transition: .5s $secondary-ease-in .2s

            .cardActions
                & > span, a
                    opacity: 1
                    transform: translate3d(0, 0, 0)
                    transition: .5s $secondary-ease-in

                & > span
                    transition-delay: .3s

                a
                    transition-delay: .35s

        .explicitTag
            opacity: 1
            transform: translate3d(0, 0, 0)
            transition: .5s $secondary-ease-in .3s

        .cover
            transform: scale3d(1.15, 1.15, 1.15)
            transition: 1s $secondary-ease-in

.cover
    @include scaleWidthHeight($list-card-width, $list-card-width)
    background-size: cover
    background-position: center
    transform: scale3d(1.5, 1.5, 1.5)
    transition: .5s $primary-ease

.cardInfo
    position: absolute
    right: 0
    bottom: 0
    left: 0
    background: $dark-ash
    padding: 12px 20px 15px
    @include scaleMaxHeight(80px, 75px)
    transform: translate(0, 75px)
    transition: .5s $primary-ease

    h3
        @include responsiveHeightText($btn-text-md)
        line-height: 1.25
        letter-spacing: -.56px
        font-family: 'Circular Std'
        font-weight: 700
        margin-bottom: 5px
        min-height: 2.5em
        display: flex
        flex-direction: column
        justify-content: center
        transition: min-height .5s $primary-ease

        & > span
            margin-left: 2px
            display: flex
            flex-direction: column

    p
        min-height: calc(1.3em + 3px)
        margin: 7px 0 17px
        color: $mild-gray
        @include responsiveHeightText($medium-text)
        line-height: 1.2
        transform: translate(0, 40px)
        opacity: 0
        transition: .5s $primary-ease

.cardFooter
    display: flex
    justify-content: space-between

.cardActions
    & > span, a
        display: inline-block
        opacity: 0
        transform: translate(0, 40px)
        transition: .5s $primary-ease

    a > span, svg
        @include scaleSvg
        transform-origin: left
        margin-right: 8px

.explicitTag
    position: absolute
    right: 25px
    opacity: 0
    transform: translate(0, 40px)
    transition: .5s $primary-ease

    svg
        @include scaleSvg
        transform-origin: right
        position: relative
        left: 5px

@keyframes cover-scale-down
    from
        transform: scale3d(1.5, 1.5, 1.5)

    to
        transform: scale3d(1, 1, 1)

@keyframes no-pointer
    from
        pointer-events: none

    to
        pointer-events: none