@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.featureCard
	width: calc((84vw - 15px) / 2)
	height: $mobile-audio-feature-card-height
	background: $mild-dark-ash
	border-radius: $medium-border-radius
	padding: 15px 13px 20px 20px

.featureSummary
	margin-top: 20px

.featureLabel
	color: $mild-gray
	font-size: $regular-text
	line-height: 1.3
	letter-spacing: 0.3px
	font-weight: 500
	@include ellipsis

.featureValue
	margin: 0
	font-size: $mobile-audio-feature-card-text
	font-family: 'Circular Std'
	line-height: 1.27
	letter-spacing: -1.75px
	font-weight: 700
	height: 1.27em
	overflow: hidden

	sub
		display: inline-block
		font-size: .5em
		transform: translateY(-.3em)

.progressBar
	position: relative
	margin-top: 15px
	height: 3px
	background: transparentize($green, .7)
	border-radius: $small-border-radius
	overflow: hidden
	transform: translateZ(0)

	& > div
		position: absolute
		top: 0
		bottom: 0
		left: 0
		background: $green