@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.loader
    @include fullCenter
    position: absolute
    top: 0
    right: 0
    left: 0
    height: calc(100vh - #{$mobile-nav-height})
    pointer-events: none
    opacity: 0
    transition: opacity .2s linear

    @include respondTo('size-sm')
        height: 80vh
    
    &.active
        opacity: 1

    svg
        @include scaleWidthHeight(60px, 60px)
        animation: spinner-spin 1.6s linear infinite
    
@keyframes spinner-spin
    from
        transform: rotate(-1080deg)

    to
        transform: rotate(0deg)