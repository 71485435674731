@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.pillText
	background: $mild-gray-alt
	display: inline-block
	margin: 0 15px 15px 0
	padding: 0 20px
	color: $white
	@include responsiveHeightText($regular-text)
	line-height: 2.5
	border-radius: calc(#{$x-large-border-radius} / 2)
	opacity: 0
	transform: translate3d(0, 40px, 0)
	transition: .7s $secondary-ease-in

	&:not(.isPassive)
		opacity: 1
		transform: translate(0, 0)
