@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.container
    @include gridContainer(17)

.sectionIcon
    grid-area: 3 / 3 / 4 / 4

.tasteSummary
    @include innerGridContainer(6)
    grid-area: 4 / 3 / 10 / 9
    z-index: 20

.isPassive
    .tasteCaption, .tasteSubcaptionText
        transform: translate(0, 100px)

        & > span
            transform: translate(0, 100%)
            opacity: 0

.tasteSummaryContent
    @include fullAbsolute
    z-index: 20

.tasteCaption
    @include sectionCaptionText
    transform: translate(0, 0)
    transition: 1s $secondary-ease-in .1s
    pointer-events: none

    & > span
        display: inline-block
        opacity: 1
        transform: translate(0, 0)
        transition: 1s $secondary-ease-in .1s

.greenText
    color: $green

    @include hoverFocusActiveState
        color: $green

.tasteSubcaption
    @include sectionSubcaptionText
    transform: translate(0, 0)
    transition: 1s $secondary-ease-in .2s

.tasteSubcaptionText
    display: inline-block
    opacity: 1
    transform: translate(0, 0)
    transition: 1s $secondary-ease-in .2s

.artistsLink
    grid-area: 2 / 9 / 10 / 17
