@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.fieldset
	padding: 0
	border: 0

	legend
		color: $mild-ash
		@include responsiveHeightText($medium-text)
		line-height: 1.29
		font-family: 'Circular Std'
		font-weight: 700