@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.trackCard
	position: relative
	width: calc((84vw - 15px) / 2)
	opacity: 1
	transform: translate3d(0, 0, 0)
	transition: .7s $secondary-ease-in
	overflow: hidden

	&.featured
		grid-area: 1 / 1 / 2 / 3
		width: 84vw

		.albumCoverImage
			width: 84vw
			height: 84vw

		.spotifyLink
			top: 20px
			right: 20px

			svg
				width: 30px
				height: 30px

		.trackInfo
			padding: 22px 20px 20px 20px

			h2
				font-size: $mobile-card-name-text
				line-height: 1.33
				letter-spacing: -1px

		.cardTitleRow
			svg
				margin-left: 10px

.isPassive
	transform: translate3d(0, 200px, 0)
	opacity: 0

	.albumCoverImage
		transform: scale3d(2, 2, 2)

	.spotifyLink
		opacity: 0
		transform: translate3d(0, 100px, 0)

	.trackInfo
		h2
			opacity: 0
			transform: translate3d(0, 100px, 0)

	.cardTitleRow
		h3, svg
			opacity: 0
			transform: translate3d(0, 100px, 0)

.withPerf
	.albumCoverImage
		will-change: transform

.albumCoverImage
	width: calc((84vw - 15px) / 2)
	height: calc((84vw - 15px) / 2)
	background-size: cover
	background-position: center
	transform: scale3d(1, 1, 1)
	transition: 1s $secondary-ease-in

.spotifyLink
	position: absolute
	top: 15px
	right: 15px
	opacity: 1
	transform: translate3d(0, 0, 0)
	transition: .7s $secondary-ease-in .1s
	z-index: 15

	svg
		width: 24px
		height: 24px

.trackInfo
	position: relative
	background: $mild-dark-ash
	padding: 15px
	border-radius: 0 0 $medium-border-radius $medium-border-radius
	transform: translateZ(0)

	h2
		margin: 3px 0 0
		font-size: $mobile-card-name-text-sm
		line-height: 1.27
		letter-spacing: -0.73px
		font-family: 'Circular Std'
		font-weight: 700
		@include ellipsis
		opacity: 1
		transform: translate3d(0, 0, 0)
		transition: .7s $secondary-ease-in .15s

.cardTitleRow
	display: flex
	align-items: center

	h3
		margin: 0
		font-size: $regular-text
		line-height: 1.5
		font-weight: 300
		opacity: 1
		transform: translate3d(0, 0, 0)
		transition: .7s $secondary-ease-in

	svg
		width: 24px
		height: 24px
		margin-left: 4px
		opacity: 1
		transform: translate3d(0, 0, 0)
		transition: .7s $secondary-ease-in .05s