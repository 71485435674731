@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.trackDetails
    @include responsiveModalGrid(360px, 630px, 90px)
    padding: 90px 0 65px

.title
    @include modalTitle
    opacity: 0
    transform: translate3d(0, 40px, 0)
    animation: swipe-up .7s $secondary-ease-in .3s forwards

    & > span
        transform: translate3d(0, 138%, 0)
        animation: swipe-up .7s $secondary-ease-in .3s forwards

.trackPlayer
    position: absolute
    top: -60px
    left: 120px
    height: $modal-action-height
    width: calc(100% - 120px)
    display: flex
    justify-content: space-between
    align-items: center
    padding-left: 30px
    border-left: thin solid transparentize($gray-alt, .6)
    animation: fade-in .7s $secondary-ease-in

    & > span
        display: inline-flex
        align-items: center
        color: $green
        @include responsiveHeightText($sm-title-text)
        line-height: 1.28
        letter-spacing: -0.75px
        font-family: 'Circular Std'
        font-weight: 500
        opacity: 0
        transform: translate3d(0, 40px, 0)
        animation: swipe-up .7s $secondary-ease-in .1s forwards

    .audioControl
        margin-right: 14px
        @include scaleWidthHeight(30px, 30px)
        border-radius: 50%
        @include fullCenter
        border: $regular-border solid $green

        & > span
            @include scaleWidthHeight(15px, 15px)
            @include fullCenter

.albumCover
    @include scaleWidthHeight($modal-main-cover-dim, $modal-main-cover-dim)
    background-size: cover
    position: relative
    clip-path: inset(0px 100% 100% 0px)
    animation: mask-out .7s $secondary-ease-in forwards

    & > div
        @include fullAbsolute
        background-size: cover
        background-position: center
        transform: scale3d(2.2, 2.2, 2.2)
        animation: main-cover-scale-down 1.5s $secondary-ease-in forwards

.playlistButton
    background: $mild-green
    opacity: 0
    transform: translate3d(0, 100px, 0)
    animation: swipe-up .7s $secondary-ease-in .1s forwards

    & > span
        transform: translate3d(0, 205%, 0)
        animation: swipe-up .7s $secondary-ease-in .1s forwards

.spotifyButton
    background: $dark-ash
    color: $green
    opacity: 0
    transform: translate3d(0, 100px, 0)
    animation: swipe-up .7s $secondary-ease-in .2s forwards

    &:hover
        color: $green

    path
        fill: $green

    & > span
        transform: translate3d(0, 205%, 0)
        animation: swipe-up .7s $secondary-ease-in .2s forwards

.trackArtists
    margin-top: 15px
    transform: translate3d(0, 40px, 0)
    animation: swipe-up .7s $secondary-ease-in .4s forwards

.quickStats
    display: flex
    margin-bottom: 25px

    & > div:first-child
        margin-right: 15px

.audioFeaturesContent
    grid-template-columns: repeat(2, auto)
    margin-bottom: 20px

    & > div
        @include scaleWidth(630px)

.featuresCards
    display: grid
    grid-template-columns: repeat(3, auto)
    grid-gap: 15px

@keyframes mask-out
    to
        clip-path: inset(0px 0px 0px 0px)

@keyframes main-cover-scale-down
    to
        transform: scale3d(1, 1, 1)

@keyframes fade-in
    from
        opacity: 0

    to
        opacity: 1

@keyframes swipe-up
    to
        opacity: 1
        transform: translate(0, 0)