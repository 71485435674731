@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.card
    @include innerGridContainer(4)
    z-index: 20

    &:hover
        .musicIconRipple > span
            animation-duration: .1s

.cardContent
    @include fullAbsolute
    z-index: 20
    background: $dark-green-alt
    display: flex
    align-items: center
    clip-path: inset(0 0 0 0 round $medium-border-radius)
    transition: .7s $secondary-ease-in .1s

    &.isPassive
        clip-path: inset(0 100% 100% 0 round $medium-border-radius)

        .coverImage
            opacity: 1
            transform: scale(2)

        .summaryBlock
            h4, p
                transform: translate(0, 140px)

        .expand > span:before
            transform: scale(6)

        .expandIcon
            opacity: 0
            transform: scale(0.8)

.playlistCover
    position: absolute
    left: 0
    width: 30vh
    height: 30vh
    z-index: 5
    overflow: hidden

    &:willAnimateIn
        .coverImage
            will-change: opacity, transform

    @include before
        @include fullAbsolute
        background: $pure-black
        opacity: .5

    @include after
        @include fullAbsolute
        background: linear-gradient(90deg, rgba(21,132,60,0) 21%, #15843C 100%)
        z-index: 3

.coverImage
    position: absolute
    width: 100%
    height: 100%
    background-size: cover
    background-position: center
    opacity: .2
    transform: scale(1)
    transform-origin: left
    transition: 1.2s $secondary-ease-in

    &:nth-child(n+2)
        mix-blend-mode: overlay

    &:nth-child(2)
        transition: 1.3s $secondary-ease-in .1s

    &:nth-child(3)
        transition: 1.4s $secondary-ease-in .2s

    &:nth-child(4)
        transition: 1.5s $secondary-ease-in .3s

.summaryBlock
    height: 100%
    width: 30vh
    flex-basis: 30vh
    display: inline-flex
    flex-direction: column
    justify-content: center
    padding: 0 25px 10px 35px
    z-index: 10

    h4
        padding-left: 2px
        @include responsiveHeightText($sm-title-text)
        line-height: 1.278
        letter-spacing: -0.5px
        font-family: 'Circular Std'
        transform: translate(0, 0)
        transition: .7s $secondary-ease-in .2s
        
    p
        margin-top: 5px
        @include responsiveHeightText($large-text)
        line-height: 1.25
        letter-spacing: -0.78px
        font-family: 'Circular Std'
        font-weight: 700
        transform: translate(0, 0)
        transition: .7s $secondary-ease-in .3s

.expand
    width: 10vh
    flex-basis: 10vh

    &.willAnimateIn
        & > span:before
            will-change: transform

        .expandIcon
            will-change: opacity, transform

    & > span
        position: relative
        @include fullCenter
        @include scaleWidthHeight(60px, 60px)
        z-index: 10

        @include before
            @include fullAbsolute
            background: $deep-green-alt
            border-radius: 50%
            transform: scale(1)
            transition: 1s $secondary-ease-in

.expandIcon
    @include fullCenter
    opacity: 1
    transform: scale(1)
    transition: .7s $ease-out-back .4s
    z-index: 3

    svg
        @include scaleWidthHeight(30px, 30px)

.musicIconRipple
    position: absolute
    
    &.openScale > span
        &:nth-child(1)
            transform: translate(-50%, -50%) scale(1)
            opacity: 1
            animation: active-ripple-oval-one 1.1s infinite

        &:nth-child(2)
            transform: translate(-50%, -50%) scale(1)
            opacity: 1
            animation: active-ripple-oval-two 1.1s infinite

    &.closeScale > span
        &:nth-child(1)
            animation: none
            opacity: 1
            transition: .5s $ease-both

        &:nth-child(2)
            animation: none
            opacity: 1
            transition: .5s $ease-both

    &.isPassive
        display: none

    & > span
        position: absolute
        top: 0
        left: 0
        border-radius: 50%
        opacity: 0
        z-index: 15

        &:nth-child(1)
            @include scaleWidthHeight(110px, 110px)
            transform: translate(-50%, -50%) scale(.45)
            background: transparentize($green, .3)
            animation: ripple-oval-one 4s 1.2s infinite

        &:nth-child(2)
            @include scaleWidthHeight(160px, 160px)
            transform: translate(-50%, -50%) scale(.31)
            background: transparentize($green, .8)
            animation: ripple-oval-two 4s 1s infinite

.musicIcon
    @include scaleWidthHeight(70px, 70px)
    position: absolute
    top: 0
    left: 0
    transform: translate(-50%, -50%)
    z-index: 25

    @include before
        @include fullAbsolute
        background: $black
        border-radius: 50%
        box-shadow: 0 calc(100vh / 34) 5vh 0 rgba(0, 0, 0, 0.8)
        transform: scale(1)
        transition: .7s $secondary-ease-in

    &.isPassive
        &:before
            transform: scale(0)

        & > span
            clip-path: circle(0%)

        svg
            transform: scale(2.77) translate(0, 50px)

    & > span
        @include fullAbsolute
        @include fullCenter
        clip-path: circle(50%)
        transition: .7s $secondary-ease-in

    svg
        @include scaleWidthHeight(22px, 24px)
        transform: scale(1) translate(0, 0px)
        transition: transform 1s $secondary-ease-in

@keyframes ripple-oval-one
    0%
        transform: translate(-50%, -50%) scale(.45)
        opacity: 1
        animation-timing-function: $secondary-ease-in

    25%
        transform: translate(-50%, -50%) scale(1)
        opacity: 0

    100%
        opacity: 0

@keyframes ripple-oval-two
    0%
        transform: translate(-50%, -50%) scale(.31)
        opacity: 1
        animation-timing-function: $secondary-ease-in

    25%
        transform: translate(-50%, -50%) scale(1)
        opacity: 0

    100%
        opacity: 0

@keyframes active-ripple-oval-one
    0%
        transform: translate(-50%, -50%) scale(.45)

    9%
        transform: translate(-50%, -50%) scale(.45)
        animation-timing-function: $ease-out

    54.5%
        transform: translate(-50%, -50%) scale(1)

    100%
        transform: translate(-50%, -50%) scale(.45)
        animation-timing-function: $ease-both

@keyframes active-ripple-oval-two
    0%
        transform: translate(-50%, -50%) scale(.31)
        animation-timing-function: $ease-out

    45.4%
        transform: translate(-50%, -50%) scale(1)

    54.5%
        transform: translate(-50%, -50%) scale(1)

    100%
        transform: translate(-50%, -50%) scale(.31)
        animation-timing-function: $ease-both
