@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.tasteArtistLink
	background: transparent
	@include innerGridContainer(8)
	z-index: 20

	&:hover
		.connector:after
			will-change: transform

	&:not(.isPassive)
		.userCover
			animation: scale-up 1s $bounce

	&:not(.hasLoaded)
		.connector
			&:after
				transition: 1.1s $bounce
			
			&:nth-child(2):after
				transition-delay: .3s

			&:nth-child(3):after
				transition-delay: .4s

			&:nth-child(4):after
				transition-delay: .5s

			&:nth-child(5):after
				transition-delay: .25s

			&:nth-child(6):after
				transition-delay: .35s

			&:nth-child(7):after
				transition-delay: .45s

			&:nth-child(8):after
				transition-delay: .55s

.isPassive
	.userCover
		transform: translate(-50%, -50%) scale(0)

	.artistCover
		clip-path: circle(0)

		&:nth-child(2)
			transform: translate(-50%, 350%)

		&:nth-child(3)
			transform: translate(-250%, 150%)

		&:nth-child(4)
			transform: translate(-350%, -50%)

		&:nth-child(5)
			transform: translate(-150%, -250%)

		&:nth-child(6)
			transform: translate(-50%, -350%)

		&:nth-child(7)
			transform: translate(150%, -250%)

		&:nth-child(8)
			transform: translate(350%, -50%)

		&:nth-child(9)
			transform: translate(150%, 150%)

	.connector:after
		transform: translate(-50%, 26.11vh)

.refreshing
	.userCover
		transition: .7s $secondary-ease-in

		&:hover
			transform: translate(-50%, -50%)

	.connector
		&:after
			transform: translate(-50%, -30vh)
			transition: .7s $secondary-ease-in

		.refresh
			transform: translate3d(-50%, 0, 0)
			transition: .7s $secondary-ease-in

		&:nth-child(2)
			&:after, .refresh
				transition-delay: .1s

		&:nth-child(3)
			&:after, .refresh
				transition-delay: .2s

		&:nth-child(4)
			&:after, .refresh
				transition-delay: .3s

		&:nth-child(5)
			&:after, .refresh
				transition-delay: .0s

		&:nth-child(6)
			&:after, .refresh
				transition-delay: .1s

		&:nth-child(7)
			&:after, .refresh
				transition-delay: .2s

		&:nth-child(8)
			&:after, .refresh
				transition-delay: .3s

.tasteArtistLinkContent
	@include fullAbsolute
	z-index: 20

.userCover
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	clip-path: circle(50%)
	z-index: 3
	transition: .5s $ease-both

	&:hover
		transform: translate(-50%, -50%) scale(1.22)

		&:not(.passiveCover)~.artistsLink 
			.connector:after
				transform: translate(-50%, 3.33vh)

.artistCover
	clip-path: circle(50%)
	position: absolute
	z-index: 2
	transition: 1.4s $bounce

	&:nth-child(2)
		top: 0
		left: 50%
		transform: translate(-50%, 50%)
		transition-delay: .1s

		.nextCover
			clip-path: circle(0 at 50% 100%)

	&:nth-child(3)
		top: 25%
		left: 75%
		transform: translate(-50%, -50%)
		transition-delay: .2s

		.nextCover
			clip-path: circle(0 at 15% 85%)
			animation-delay: .1s

			.cover
				animation-delay: .1s

	&:nth-child(4)
		top: 50%
		right: 0
		transform: translate(-50%, -50%)
		transition-delay: .3s

		.nextCover
			clip-path: circle(0 at 0% 50%)
			animation-delay: .2s

			.cover
				animation-delay: .2s

	&:nth-child(5)
		top: 75%
		right: 25%
		transform: translate(50%, -50%)
		transition-delay: .4s

		.nextCover
			clip-path: circle(0 at 15% 15%)
			animation-delay: .3s

			.cover
				animation-delay: .3s

	&:nth-child(6)
		bottom: 0
		left: 50%
		transform: translate(-50%, -50%)
		transition-delay: .15s

		.nextCover
			clip-path: circle(0 at 50% 0%)

	&:nth-child(7)
		top: 75%
		left: 25%
		transform: translate(-50%, -50%)
		transition-delay: .25s

		.nextCover
			clip-path: circle(0 at 85% 15%)
			animation-delay: .1s

			.cover
				animation-delay: .1s

	&:nth-child(8)
		top: 50%
		left: 0
		transform: translate(50%, -50%)
		transition-delay: .35s

		.nextCover
			clip-path: circle(0 at 100% 50%)
			animation-delay: .2s

			.cover
				animation-delay: .2s

	&:nth-child(9)
		top: 25%
		left: 25%
		transform: translate(-50%, -50%)
		transition-delay: .45s

		.nextCover
			clip-path: circle(0 at 85% 85%)
			animation-delay: .3s

			.cover
				animation-delay: .3s

.cover
	width: 10vh
	height: 10vh
	background-size: cover
	background-position: center
	border-radius: 50%

.nextCover
	position: absolute
	top: 0
	z-index: 10
	animation: next-artist-mask-out .7s $secondary-ease-in forwards

	.cover
		transform: scale(2.22)
		animation: next-cover-scale-down 1s $secondary-ease-in forwards

.artistsLink
	position: absolute
	top: 50%
	left: 50%
	width: 60vh
	height: 60vh
	transform: translate(-50%, -50%)
	pointer-events: none

.connector
	position: absolute
	left: 50%
	width: 1px
	height: 30vh
	transform-origin: bottom center
	overflow: hidden

	&:not(.connectorPassive):after
		transition: .5s $ease-both

	@include after
		position: absolute
		top: 13%
		bottom: 13%
		left: 0
		width: 22.22vh
		transform: translate(-50%, 0)
		background: radial-gradient(circle, #33FF7A 0%, rgba(51,255,122,0) 65%)
		border-radius: 50%

	&:nth-child(2)
		transform: rotate(45deg)

	&:nth-child(3)
		transform: rotate(90deg)

	&:nth-child(4)
		transform: rotate(135deg)

	&:nth-child(5)
		transform: rotate(180deg)

	&:nth-child(6)
		transform: rotate(225deg)

	&:nth-child(7)
		transform: rotate(270deg)

	&:nth-child(8)
		transform: rotate(315deg)

.refresh
	position: absolute
	top: 13%
	bottom: 13%
	left: 0
	width: 22.22vh
	background: radial-gradient(circle, #33FF7A 0%, rgba(51,255,122,0) 65%)
	border-radius: 50%
	transform: translate(-50%, 26.11vh)

@keyframes scale-up
	from
		pointer-events: none
		transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0)

	to
		pointer-events: none
		transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1)

@keyframes next-artist-mask-out
	to
		clip-path: circle(50%)

@keyframes next-cover-scale-down
	to
		transform: scale(1)