@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.container
	@include gridContainer(23)

.isPassive
	.teamCaption, .teamSubcaption
		transform: translate(0, 100px)

		& > span
			transform: translate(0, 100%)
			opacity: 0

.sectionIcon
	grid-area: 3 / 2 / 4 / 3

.teamSummary
	@include innerGridContainer(6)
	grid-area: 4 / 2 / 8 / 8
	z-index: 20
	pointer-events: none

.teamSummaryContent
	@include fullAbsolute
	z-index: 20

.teamCaption
	@include sectionCaptionText($team-caption-text, 15px, 1.059, -9.44px)
	transform: translate(0, 0)
	transition: 1s $secondary-ease-in .1s
	pointer-events: none

	& > span
		display: inline-block
		opacity: 1
		transform: translate(0, 0)
		transition: 1s $secondary-ease-in .1s

.greenText
	color: $green

.teamSubcaption
	@include sectionSubcaptionText
	transform: translate(0, 0)
	transition: 1s $secondary-ease-in .2s

	& > span
		display: inline-block
		opacity: 1
		transform: translate(0, 0)
		transition: 1s $secondary-ease-in .2s

.memberOne
	grid-area: 3 / 9 / 8 / 13

.memberTwo
	grid-area: 5 / 14 / 10 / 18

.memberThree
	grid-area: 3 / 19 / 8 / 23

.teamMemberCardContainer
	@include innerGridContainer(4)
	z-index: 10

.isPassiveMember
	.teamMemberCard
		clip-path: inset(0px 100% 100% 0px round $small-border-radius)

	.memberImage
		transform: scale(2.22)

	.memberName, .social a
		transform: translate(0, 170px)

.teamMemberCard
	@include fullAbsolute
	z-index: 20
	padding: 30px
	border-radius: $small-border-radius
	clip-path: inset(0px 0px 0px 0px round $small-border-radius)
	display: flex
	flex-direction: column
	justify-content: flex-end
	align-items: center
	overflow: hidden
	transition: .7s $secondary-ease-in .1s

	@include before
		@include fullAbsolute
		background: linear-gradient(180deg, rgba(17,17,18,0) 0%, rgba(17,17,18,0.6) 100%)
		z-index: -1

.memberImage
	position: absolute
	top: 0
	left: 0
	background-size: cover
	background-position: center
	width: 40vh
	height: 50vh
	transform: scale(1)
	transition: 1.5s $secondary-ease-in .1s
	z-index: -2

.memberName
	@include responsiveHeightText($lg-title-text)
	line-height: 1.278
	letter-spacing: -1px
	font-family: 'Circular Std'
	font-weight: 700
	text-align: center
	transform: translate(0, 0)
	transition: .7s $secondary-ease-in .3s

.social
	margin-top: 10px

	a
		display: inline-block
		transition: .7s $secondary-ease-in .3s

		&:nth-child(2)
			transition-delay: .4s

		&:nth-child(3)
			transition-delay: .5s

		&:not(:last-child)
			margin-right: 15px

	svg
		@include scaleWidthHeight(30px, 30px)

.projectPlaylist
	grid-area: 3 / 14 / 4 / 19
