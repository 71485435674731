@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.dots
	width: 18.44vh
	height: 30vh
	display: flex
	flex-direction: column
	justify-content: space-around
	transform: translate(1.56vh, -6.22vh)

	&:not(.animatedIn)
		& > div > span
			will-change: transform

	& > div
		display: grid
		justify-content: space-between
		grid-template-columns: repeat(4, auto)
		transform: translate3d(0, 0, 0)
		transition: .7s $pop

		@for $row from 0 through 4
			&:nth-child(#{$row + 1})
				transition-delay: .1s * $row

				& > span
					@for $column from 0 through 3
						&:nth-child(#{$column + 1})
							transition-delay: .1s * $row + .05s * $column

		& > span
			@include scaleSvg
			width: $dot-dimension
			height: $dot-dimension
			background: $green
			opacity: .9
			border-radius: 50%
			transition: .7s $pop

.isPassiveDotPattern
	& > div
		transform: translate3d(-5px, 5px, 0)

		& > span
			transform: scale(0) translate(-3px, 7px)

.animatedIn
	& > div
		transform: translate(0, 0)