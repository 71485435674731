@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.switchToggle
	@include scaleWidthHeight($switch-width, $switch-height)
	border-radius: $regular-border-radius
	display: flex
	align-items: center
	background: $white
	transform: translateZ(0)
	transition: .7s $secondary-ease-in

	@include after
		position: absolute
		@include scaleWidthHeight($switch-knob-dim, $switch-knob-dim)
		flex-shrink: 1
		background: $black
		clip-path: circle(48%)
		transform: translate(22%, 0)
		transition: .7s $secondary-ease-in

.isActive
	background: $green

	&:after
		transform: translate(128%, 0)