@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.landing
	margin-top: 80px

.caption
	text-align: center
	margin: 0
	padding: 0 2vw
	font-size: $xl-large-text
	line-height: 1.2
	letter-spacing: -2.4px
	font-family: 'Circular Std'
	font-weight: 900
	overflow: hidden

	& > span
		display: inline-block
		transform: translate3d(0, 100%, 0)
		animation: swipe-up 1s $secondary-ease-in 1.1s forwards

.listen
	color: $green

.description
	margin: 20px 8vw
	padding: 0 20px
	font-size: $sm-title-text
	line-height: 1.89
	font-weight: 300
	text-align: center
	overflow: hidden

	& > span
		display: inline-block
		transform: translate3d(0, 100%, 0)
		animation: swipe-up 1s $secondary-ease-in 1.2s forwards

.loginButton
	@include fullCenter
	margin: auto
	width: calc(100% - 16vw - 40px)
	min-width: $mobile-login-button-min-width
	background: $mild-gray
	color: $dark-ash
	font-size: $login-btn-text
	line-height: 1.33
	opacity: 0
	transform: translate3d(0, 40px, 0)
	overflow: hidden
	animation: swipe-up 1s $secondary-ease-in 1.3s forwards
	
	& > span
		@include fullCenter
		transform: translate3d(0, 205%, 0)
		animation: swipe-up 1s $secondary-ease-in 1.4s forwards

	svg
		margin-right: 10px

		path
			fill: $dark-ash

.artistsBubblesContainer
	height: $artist-bubbles-height
	transform: translate(calc(100vw - 360px), 0)
	animation: swipe-left 2.4s $secondary-ease-in 1.2s forwards

.artistsBubbles
	margin-top: 20px
	width: 100%
	position: relative

.exploreArtist
	position: absolute
	transform: translateZ(0)

	&:nth-child(6n + 2)
		transform: translate3d(-10px, 0, 0)

	&:nth-child(6n + 3)
		transform: translate3d(10px, 30px, 0)

	&:nth-child(6n + 4)
		transform: translate3d(-10px, 40px, 0)

	&:nth-child(6n + 5)
		transform: translate3d(0, 20px, 0)

	&:nth-child(6n + 6)
		transform: translate3d(-20px, 20px, 0)

	@for $i from 0 through 10
		&:nth-child(#{$i + 5})
			.artistCoverImage
				animation-duration: .7s
				animation-delay: 1.2s + $i * .2s

				& > div
					animation-duration: 1s
					animation-delay: 1.2s + $i * .2s

			.popularityIcon
				animation-duration: .7s
				animation-delay: 1.3s + $i * .2s

				&:after
					animation-duration: .7s
					animation-delay: 1.3s + $i * .2s

				& > span
					animation-duration: .7s
					animation-delay: 1.3s + $i * .2s

.artistCover
	width: $artist-bubble-dim
	height: $artist-bubble-dim
	clip-path: circle(50%)
	transform: translateZ(0)

	&.animatedIn
		.artistCoverImage
			clip-path: circle(50%)
			animation: none

			& > div
				transform: scale3d(1, 1, 1)
				animation: none

	.artistCoverImage
		width: 100%
		height: 100%
		clip-path: circle(0)
		animation: mask-out .1s $secondary-ease-in 2.9s forwards
		transform: translateZ(0)

		& > div
			width: 100%
			height: 100%
			background-size: cover
			background-position: center
			transform: scale3d(2, 2, 2)
			animation: scale-out .1s $secondary-ease-in 2.9s forwards

.popularityIcon
	position: absolute
	right: 0
	bottom: 0
	@include fullCenter
	width: $artist-bubble-popularity-dim
	height: $artist-bubble-popularity-dim
	background: $black
	clip-path: circle(0)
	animation: mask-out .1s $secondary-ease-in 2.9s forwards
	transform: translateZ(0)

	&.animatedIn
		clip-path: circle(50%)
		animation: none

		&:after
			clip-path: circle(calc(50% - 3px))
			animation: none

		& > span
			transform: translateY(0) scale(.64)
			animation: none

	@include after
		@include fullAbsolute
		background: $mild-dark-ash-alt
		clip-path: circle(calc(0% - 3px))
		animation: bordered-mask-out .1s $secondary-ease-in 2.9s forwards

	& > span
		z-index: 5
		transform: translateY(40px) scale(.64)
		animation: popularity-icon-swipe-up .1s $secondary-ease-in 2.9s forwards

@keyframes swipe-up
	to
		opacity: 1
		transform: translate(0, 0)

@keyframes popularity-icon-swipe-up
	to
		transform: translateY(0) scale(.64)

@keyframes swipe-left
	to
		transform: translate(-$artist-bubbles-container-x, 0)

@keyframes mask-out
	to
		clip-path: circle(50%)

@keyframes bordered-mask-out
	to
		clip-path: circle(calc(50% - 3px))

@keyframes scale-out
	to
		transform: scale3d(1, 1, 1)