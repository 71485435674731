@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.footer
    position: fixed
    right: 0
    bottom: 0
    left: 0
    height: 10vh
    display: flex
    align-items: center
    opacity: 0
    z-index: -10

    @include respondTo('size-sm')
        opacity: 1
        z-index: 90

.navigationTip
    display: flex
    align-items: center
    @include responsiveHeightText($sm-title-text)
    line-height: 1.44
    letter-spacing: -0.5px
    font-family: 'Circular Std'
    font-weight: 300

.navigationTipIcon
    @include fullCenter
    width: 10vh
    flex-basis: 10vh

    & > svg
        @include scaleSvg