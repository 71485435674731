@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.container
	@include gridContainer(12)
	position: relative

.sectionIcon
	grid-area: 3 / 1 / 4 / 2

.tasteSummary
	@include innerGridContainer(5)
	grid-area: 4 / 1 / 10 / 6
	z-index: 20

.isPassive
	.tasteCaption > span
		transform: translate(0, 100%)
		opacity: 0

	.tasteShare
		transform: translate(0, 80px)
		opacity: 0

	.tasteInfoContent p
		transform: translate(0, 80px)
		opacity: 0

.tasteSummaryContent
	@include fullAbsolute
	z-index: 20

.tasteCaption
	@include sectionCaptionText
	pointer-events: none

	& > span
		display: inline-block
		opacity: 1
		transform: translate(0, 0)
		transition: 1s $secondary-ease-in .1s

.greenText
	color: $green

.tasteShare
	margin-top: 18px
	display: flex
	opacity: 1
	transform: translate(0, 0)
	transition: .7s $secondary-ease-in .2s

.artistCovers
	@include scaleWidth(168px)
	display: flex
	z-index: 10
	@include scaleSvg
	transform-origin: left top

.artistCover
	width: $taste-profile-artist-cover-dim
	height: $taste-profile-artist-cover-dim
	flex-shrink: 0
	background-size: cover
	background-position: center
	border-radius: 50%
	border: $lg-border solid $black

	&:nth-child(2)
		transform: translateX(-30%)
		z-index: -1

	&:nth-child(3)
		transform: translateX(-60%)
		z-index: -2

	&:nth-child(4)
		transform: translateX(-90%)
		z-index: -3

.shareAction
	background: $black
	@include scaleHeight($taste-profile-artist-cover-dim - 10)
	margin: 5px 0 0 10px
	padding: 0 20px 0 10px
	color: $green
	@include responsiveHeightText($sm-title-text)
	letter-spacing: -0.75px
	line-height: 1.278
	font-family: 'Circular Std'
	font-weight: 500
	border: $regular-border solid $green
	border-radius: $x-large-border-radius
	overflow: hidden

	&:hover
		& > div
			transform: translate(0, calc(-100% + 2px))

	& > div
		height: 100%
		transform: translate(0, 0)
		transition: .5s $secondary-ease-in

.shareThis
	height: 100%
	display: flex
	align-items: center

	svg
		margin-right: 8px
		@include scaleWidthHeight(30px, 30px)

	path
		fill: $green

.sharePlatforms
	height: 100%
	display: flex
	align-items: center
	justify-content: space-around

	& > span:last-child
		opacity: 0.35

	svg
		@include scaleWidthHeight(30px, 30px)

.tasteIconBg
	position: absolute
	bottom: 0
	left: 5.89vh
	mix-blend-mode: overlay
	z-index: 40
	pointer-events: none
	display: none

	& > svg
		width: 109.55vh
		height: 109.55vh

.tasteInfo
	@include innerGridContainer(4)
	grid-area: 3 / 7 / 6 / 11
	z-index: 20
	pointer-events: none

.tasteInfoContent
	position: absolute
	right: 0
	bottom: 45px
	left: 0
	z-index: 20

	p
		@include responsiveHeightText($lg-title-text)
		letter-spacing: -2px
		line-height: 1.5
		font-family: 'Circular Std'
		font-weight: 700
		opacity: 1
		transform: translate(0, 0)
		transition: .7s $secondary-ease-in .2s

.whipPlaylist
	grid-area: 6 / 7 / 8 / 11
	z-index: 30
