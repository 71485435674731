@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.carousel
	margin-bottom: 20px
	@include scaleWidth(600px, 30px)
	overflow: hidden

	nav
		display: flex
		justify-content: space-between
		margin-bottom: 20px

		&:not(.isPassive)
			h4
				transform: translate(0, 0)

				& > span
					transform: translate(0, 0)

			.carouselControls
				opacity: 1

		h4
			@include responsiveHeightText($carousel-title-text)
			line-height: 1.4
			letter-spacing: -1px
			font-family: 'Circular Std'
			font-weight: 700
			overflow: hidden
			transform: translate3d(0, 40px, 0)
			transition: .7s $secondary-ease-in

			& > span
				display: inline-block
				transform: translate3d(0, 170%, 0)
				transition: .7s $secondary-ease-in

.carouselControls
	opacity: 0
	transition: .7s $secondary-ease-in
	
	svg
		@include scaleSvg
		transform-origin: right
		margin-left: 20px
		opacity: .4

.activeChevron svg
	opacity: 1

.carouselContents
	display: grid
	grid-template-columns: repeat(6, auto)
	grid-column-gap: 15px
	transition: .5s $secondary-ease-in