@import '../../sass/abstracts/variables'
@import '../../sass/abstracts/mixins.sass'

.modal
	position: fixed
	top: 0
	right: 0
	bottom: 0
	left: 0
	z-index: 100
	background: transparentize($black, .3)
	@include fullCenter

	@include before
		position: absolute
		@include scaleWidth(990px, (270px + ($xl-border * 2)))
		height: calc(80vh + #{$xl-border * 2})
		border-radius: $medium-border-radius * 2
		background: $black
		animation: fade-scale-up .3s $secondary-ease-in

.modalContent
	position: relative
	@include scaleWidth(990px, 270px)
	height: 80vh
	border-radius: $medium-border-radius * 2
	padding: 0 50px
	overflow: hidden
	animation: scale-up .3s $secondary-ease-in

	@include before
		@include fullAbsolute
		background: $mild-dark-ash-alt
		border-radius: $medium-border-radius * 2
		animation: fade-in .3s $secondary-ease-in
		
.close
	position: absolute
	top: 30px
	right: 30px
	z-index: 10

.contentArea
	display: flex
	justify-content: center

	& > div
		width: 100%
		min-height: 10vh

.scrollBar
	position: absolute
	top: 90px
	right: 42px
	bottom: 90px
	width: $scrollbarWidth
	background: transparentize($white, .9)
	border-radius: $small-border-radius
	opacity: 1
	transition: .7s $secondary-ease-in
	z-index: 10

	&.disabledBar
		opacity: .39

.hideBar
	opacity: 0
	transition: 0s

.scrollThumb
	position: absolute
	top: 0
	right: 0
	left: 0
	border-radius: $small-border-radius
	overflow: hidden

	@include after
		@include fullAbsolute
		background: $mild-green
		transform: scale3d(1, 1, 1)
		transform-origin: top
		transition: .7s $secondary-ease-in

.hideThumb
	&:after
		transform: scale3d(1, 0, 1)
		transition: none

.modalNav
	position: absolute
	top: 35px
	right: 0
	left: 0
	display: flex
	justify-content: center
	opacity: 1
	transition: .7s $secondary-ease-in

	& > div
		@include scaleWidth(990px, 90px)

	.navControl
		margin-right: 35px
		position: relative
		z-index: 20

		&.disabled
			pointer-events: none

			path
				fill: $mild-gray-alt

		path
			transition: .2s $secondary-ease-in

.hideNav
	opacity: 0

@keyframes scale-up
	from
		transform: scale3d(0.5, 0.5, 1)

	to
		transform: scale3d(1, 1, 1)

@keyframes fade-scale-up
	from
		transform: scale3d(0.5, 0.5, 1)
		opacity: 0

	to
		transform: scale3d(1, 1, 1)
		opacity: 1

@keyframes fade-in
	from
		opacity: 0

	to
		opacity: 1
