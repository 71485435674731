@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.container
	margin-top: 40px
	padding: 0 8vw

.userSummary
	margin: 10px 0 15px

.topGenreCaption
	@include mobileSectionCaptionText(40px)

	& > span
		display: inline-block
		transition: 1s $secondary-ease-in .1s
		transform: translate(0, 0)

.greenText
	color: $green

.topGenreSubcaption
	@include mobileSectionSubcaptionText

	& > span
		display: inline-block
		transform: translate(0, 0)

.genreBar
	position: relative
	height: $mobile-genre-bar-height
	margin-bottom: 15px
	padding: 15px 25px
	display: flex
	align-items: center
	transform-origin: left
	clip-path: inset(0 0 0 0 round $medium-border-radius)
	overflow: hidden

	@include before
		@include fullAbsolute
		background: $mild-dark-ash

.genreBarLevel
	position: absolute
	top: 0
	bottom: 0
	left: 0
	transform: translate(0, 0)
	transform-origin: left

.genreName
	width: calc(100% - 60px)
	flex-basis: calc(100% - 60px)
	position: relative
	font-size: $mobile-bar-name-text
	font-family: 'Circular Std'
	line-height: 1.35
	letter-spacing: -0.67px
	font-weight: 700
	text-transform: capitalize
	@include ellipsis
	z-index: 10

.genreBarLevelText
	opacity: 0.6
	width: 60px
	flex-basis: 60px
	text-align: right
	font-size: $mobile-bar-level-text
	font-family: 'Circular Std'
	line-height: 1.35
	letter-spacing: -0.67px
	font-weight: 700
	z-index: 10
