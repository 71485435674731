@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.container
	margin-top: 40px
	margin-bottom: 150px
	padding: 0 8vw

.userSummary
	margin: 10px 0

	&.isPassive
		.streamingAnalysisCaption, .streamingAnalysisSubcaption
			transform: translate(0, 40px)

			& > span
				transform: translate(0, 100%)
				opacity: 0

.streamingAnalysisCaption
	@include mobileSectionCaptionText
	transition: 1s $secondary-ease-in

	&:nth-child(2)
		transition-delay: .1s

		& > span
			transition-delay: .1s

	& > span
		display: inline-block
		transition: 1s $secondary-ease-in
		transform: translate(0, 0)

.greenText
	color: $green

.streamingAnalysisSubcaption
	@include mobileSectionSubcaptionText
	transition: 1s $secondary-ease-in .2s

	& > span
		display: inline-block
		transition: 1s $secondary-ease-in .2s
		transform: translate(0, 0)

.tabSwitch
	margin: 30px 30px 0

.analysis
	height: $mobile-streaming-tab-height
	padding-top: 20px

	&.isPassive
		.featureRing
			clip-path: circle(0)

			& > span
				&:before
					border-color: $black

				svg
					transform: scale(0)

		.featureInfo
			clip-path: circle(0)

			svg
				transform: translate(0, 25px)

		.activeFeatureDetails
			h4 > span
				opacity: 0
				transform: translateY(146px) scale(0)

		.activeFeatureBg
			svg
				opacity: 0
				transform: scale3d(2, 2, 2)

.features
	position: relative
	width: 100%
	height: 100%
	user-select: none
	z-index: 10

.featureRing
	position: absolute
	clip-path: circle(70%)
	transition: .5s $secondary-ease-in

	& > svg
		position: absolute
		width: 84px
		height: 84px
		opacity: 0
		transform: translate(-12px, -12px)
		pointer-events: none
		transition: .7s $secondary-ease-in

		circle
			&:first-child
				stroke-width: 2.14

			&:last-child
				stroke-width: 3.21
				stroke-dasharray: 252.1, 252.1

	& > span
		@include fullCenter
		width: 60px
		height: 60px
		border-radius: 50%

		@include before
			@include fullAbsolute
			opacity: 1
			border: $md-border + 1 solid $black
			border-radius: 50%
			background: linear-gradient(90deg, #252527 0%, #252527 100%)
			transition: .7s $secondary-ease-in, border-width .25s $secondary-ease-in
			z-index: 4

		@include after
			@include fullAbsolute
			opacity: 0
			border: $md-border + 1 solid $black
			border-radius: 50%
			transition: .7s $secondary-ease-in, border-width .25s $secondary-ease-in
			z-index: 5

		svg
			width: 30px
			height: 30px
			transform: scale(1)
			transition: .69s $secondary-ease-in
			z-index: 10

		path
			fill: $green
			transition: .7s $secondary-ease-in

	&:nth-child(1)
		left: 50%
		transform: translate(-50%, 0)
		transition-delay: .3s

		& > span
			&:after
				background: linear-gradient(135.57deg, #1DB954 0%, #7B9D19 100%)

			svg
				transition-delay: .4s

	&:nth-child(2)
		top: 29.4%
		right: 0
		transform: translate(0, -50%)
		transition-delay: .4s

		& > span
			&:after
				background: linear-gradient(135.57deg, #1DA1F2 0%, #A727EE 100%)

			svg
				transition-delay: .5s

	&:nth-child(3)
		right: 0
		bottom: 29.4%
		transform: translate(0, 50%)
		transition-delay: .5s

		& > span
			&:after
				background: linear-gradient(136.15deg, #CCAE55 0%, #95690B 100%)

			svg
				transition-delay: .6s

	&:nth-child(4)
		bottom: 0
		left: 50%
		transform: translate(-50%, 0)
		transition-delay: .6s

		& > span
			&:after
				background: linear-gradient(268.81deg, #3FE4D0 0%, #1B52DF 100%)

			svg
				transition-delay: .7s

	&:nth-child(5)
		bottom: 29.4%
		left: 0
		transform: translate(0, 50%)
		transition-delay: .7s

		& > span
			&:after
				background: linear-gradient(135.57deg, #FA9917 0%, #A727EE 47%, #4360DE 100%)

			svg
				transition-delay: .8s

	&:nth-child(6)
		top: 29.4%
		left: 0
		transform: translate(0, -50%)
		transition-delay: .8s

		& > span
			&:after
				background: linear-gradient(131.92deg, #CAB000 0%, #FA9917 100%)

			svg
				transition-delay: .9s

.activeRing
	&.passiveRing
		& > span:before
			opacity: 1
			border-width: 0
			transition: border-width .5s $secondary-ease-in .3s

	& > svg
		opacity: 1
		transition: .4s $secondary-ease-in .5s

	circle:last-child
		animation: ring-progress 3s linear .5s forwards

	& > span
		&:before
			opacity: 0
			border-width: 0
			transition-delay: .2s

		&:after
			opacity: 1
			border-width: 0
			transition-delay: .2s

		path
			fill: $white
			transition-delay: .2s

	&.firstCycle
		& > svg
			transition: 1s $secondary-ease-in

		circle:last-child
			animation-delay: 0s

		& > span
			&:after
				border-width: 0

			path
				transition-delay: 0s

.passiveRing
	& > svg
		opacity: 0

	circle:last-child
		animation-play-state: paused

	& > span
		&:after
			opacity: 0

		path
			fill: $green

.pauseRing
	circle:last-child
		animation-play-state: paused

.featureDetails
	position: absolute
	top: 29.4%
	right: 30px
	bottom: 29.4%
	left: 30px
	text-align: center
	z-index: -5

	&.firstCycle
		.activeFeatureDetails
			h4 > span
				animation: none
				transition: 1s $secondary-ease-in .8s

	h4
		position: relative
		height: 1.25em
		font-size: $md-title-text
		line-height: 1.25
		letter-spacing: -0.44px
		font-family: 'Circular Std'
		font-weight: 700

	h2
		position: relative
		height: 1.03em
		color: $green
		font-size: $insight-label-text
		line-height: 1.03
		letter-spacing: -2.33px
		font-family: 'Circular Std'
		font-weight: 900

	span
		display: inline-block

.activeFeatureDetails
	span
		opacity: 1
		transform: translateY(0) scale(1)

	h2
		text-align: left
		left: 50%

		& > span
			position: absolute
			opacity: 0

	h4 > span
		animation: details-label-in 1.2s $secondary-ease-in

.activeValueAnimatable
	&.isActive > span
		opacity: 1
		transition: transform 1s $secondary-ease-in

	& > span
		display: inline-block
		position: absolute
		top: 0
		left: 0
		color: $green
		font-size: $insight-label-text
		line-height: 1.03
		letter-spacing: -2.33px
		font-family: 'Circular Std'
		font-weight: 900
		opacity: 0
		transform-origin: top left

.activeValueStartPosition
	position: absolute
	right: 0
	bottom: 0
	left: 0
	font-size: 1px
	line-height: 0
	letter-spacing: 40px
	font-family: 'Circular Std'
	font-weight: 900
	opacity: 0

.previousFeatureDetails
	@include fullAbsolute

	span
		position: absolute
		top: 0
		right: 0
		left: 0
		display: inline-block
		opacity: 1
		transform: translateY(0) scale(1)
		transition: .7s $secondary-ease-in

	h4 > span
		animation: details-label-out .7s $secondary-ease-in .2s forwards

	h2 > span
		animation: details-value-out .7s $secondary-ease-in .2s forwards

.featureInfo
	position: absolute
	left: 50%
	bottom: 29.4%
	@include fullCenter
	width: 30px
	height: 30px
	background: $dark-ash
	clip-path: circle(48%)
	transform: translate3d(-50%, 50%, 0)
	transition: .7s $secondary-ease-in

	svg
		transform: translate(0, 0)
		transition: .7s $secondary-ease-in

	path
		fill: $white

.featureBackground
	pointer-events: none
	@include fullAbsolute
	@include fullCenter
	transform: translate(0, 5px)
	z-index: -2

.activeFeatureBg
	&.firstCycle
		svg
			animation: none
	svg
		opacity: 1
		transform: translate3d(1, 1, 1)
		transition: 2s $secondary-ease-in .3s
		animation: fade-in-bg 2s $secondary-ease-in forwards

.previousFeatureBg
	svg
		opacity: 1
		transform: translate3d(1, 1, 1)
		animation: fade-out-bg .7s $secondary-ease-in forwards

.insights
	height: $mobile-streaming-tab-height

@keyframes ring-progress
	from
		stroke-dasharray: 0, 252.1

	to
		stroke-dasharray: 252.1, 252.1

@keyframes details-label-in
	0%
		opacity: 0
		transform: translateY(146px) scale(0)

	16.66%
		animation-timing-function: $secondary-ease-in
		opacity: 0
		transform: translateY(146px) scale(0)

@keyframes details-label-out
	to
		opacity: 0
		transform: translateY(0) scale(0)

@keyframes details-value-out

	to
		opacity: 0
		transform: translateY(-30px) scale(0)

@keyframes fade-in-bg
	from
		opacity: 0
		transform: scale3d(2, 2, 2)

@keyframes fade-out-bg
	to
		opacity: 0
		transform: scale3d(.5, .5, .5)
