@import '../../sass/abstracts/variables'
@import '../../sass/abstracts/mixins.sass'

.modal
	position: fixed
	top: 0
	right: 0
	bottom: 0
	left: 0
	z-index: 100

	@include before
		@include fullAbsolute
		background: $black
		opacity: 0
		animation: fade-in .3s $secondary-ease-in forwards

.willClose
	pointer-events: none
	opacity: 0
	transition: opacity .3s $secondary-ease-in .3s

	.closeModal
		animation: swipe-close-down .5s $secondary-ease-in

	.modalNav
		& > div:before
			opacity: 0
			transform: translate3d(0, 60px, 0)
			transition: .5s $secondary-ease-in .1s

		.navControl
			opacity: 0
			transform: translate3d(0, 60px, 0)
			transition: .5s $secondary-ease-in .2s

			&:last-child
				transition-delay: .15s

.modalNav
	position: absolute
	top: 0
	right: 0
	left: 0
	height: $mobile-nav-height
	padding: 0 8vw
	display: flex
	justify-content: space-between
	align-items: center

	& > div
		position: relative
		height: 38px
		display: flex
		z-index: 10

		@include before
			position: absolute
			top: 0
			right: 0
			bottom: 0
			width: 1px
			background: transparentize($gray-alt, .6)
			opacity: 1
			transform: translate3d(0, 0, 0)
			transition: .7s $secondary-ease-in .3s

	span
		display: flex
		align-items: center

	.navCollapse
		position: absolute
		opacity: 0
		pointer-events: none
		transition: .3s $secondary-ease-in
		clip-path: circle(50%)

		svg > g
			transform-origin: center
			transform: scale(2.7) translate3d(0, 15px, 0)
			transition: .3s $secondary-ease-in

	.navControl
		margin-right: 5px
		opacity: 1
		transform: translate3d(0, 0, 0)
		transition: .7s $secondary-ease-in .2s

		@include respondTo('size-xs')
			margin-right: 15px

		&:last-child
			transition-delay: .25s

		&.disabled
			pointer-events: none

			svg
				opacity: 0.3

		svg
			transition: .2s $secondary-ease-in

			& > g
				transition: .5s $secondary-ease-in .3s

.hideNav
	& > div:before, .navControl
		opacity: 0
		transform: translate3d(0, 60px, 0)

.expandedNav
	& > div:before
		transform: translate3d(-25px, 0, 0)
		transition: .5s $secondary-ease-in

		@include respondTo('size-xs')
			transform: translate3d(-38px, 0, 0)

	.navCollapse
		opacity: 1
		pointer-events: all
		transition-duration: .5s

		svg > g
			transform: scale(1) translate3d(0, 0, 0)
			transition-duration: .5s

	.navControl
		pointer-events: none

		svg > g
			opacity: 0
			transition: .3s $secondary-ease-in

	.closeModal
		pointer-events: none

.collapsedNav
	& > div:before
		transition: .5s $secondary-ease-in .3s

.closeModal
	position: relative
	opacity: 0
	transform: translate3d(0, 60px, 0)
	animation: swipe-up .7s $secondary-ease-in .3s forwards

.contentArea
	position: relative
	margin-top: $mobile-nav-height
	z-index: 10

	&.animateIn
		& > div
			opacity: 0
			transform: translate3d(0, 200px, 0)
			animation: swipe-up .7s $secondary-ease-in forwards

	&.canPushUp
		& > div
			transition-duration: .7s

	& > div
		min-height: calc(100vh - #{$mobile-nav-height})
		background: $mild-dark-ash-alt
		clip-path: inset(0 0 0 0 round 30px 30px 0 0)
		opacity: 1
		transform: translate3d(0, 0, 0)
		transition: transform 0s $primary-ease
		overflow: hidden

@keyframes fade-in
	to
		opacity: 1

@keyframes swipe-up
	to
		opacity: 1
		transform: translate3d(0, 0, 0)

@keyframes swipe-close-down
	from
		opacity: 1
		transform: translate3d(0, 0, 0)
