@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.suggestionPlaylistCreator
	@include responsiveModalGrid(360px, 450px, 90px)
	padding: 110px 0 50px

.playlistCover
	margin-top: 20px
	@include scaleWidthHeight($modal-main-cover-dim, $modal-main-cover-dim)
	position: relative
	overflow: hidden
	clip-path: inset(0px 100% 100% 0px round $medium-border-radius $medium-border-radius)
	animation: mask-out .7s $secondary-ease-in forwards

	& > div
		width: 100%
		height: 100%
		background-size: cover
		background-position: center
		transform: scale3d(2.2, 2.2, 2.2)
		animation: main-cover-scale-down 1.5s $secondary-ease-in forwards

.playlistDetails
	h1
		margin: 0
		@include responsiveHeightText($caption-text)
		line-height: 1.26
		letter-spacing: -5px
		font-family: 'Circular Std'
		font-weight: 900
		overflow: hidden
		transform: translate3d(0, 80px, 0)
		animation: swipe-up .7s $secondary-ease-in .1s forwards

		&:nth-child(2)
			animation-delay: .2s

			& > span
				animation-delay: .2s

		& > span
			display: inline-block
			opacity: 0
			transform: translate3d(0, 100px, 0)
			animation: swipe-up .7s $secondary-ease-in .1s forwards

.greenText
	color: $green

.playlistSummary
	margin-top: 15px
	max-width: 80%
	@include responsiveHeightText($sub-caption-text)
	line-height: 2
	overflow: hidden
	transform: translate3d(0, 80px, 0)
	animation: swipe-up .7s $secondary-ease-in .3s forwards

	& > span
		display: inline-block
		opacity: 0
		transform: translate3d(0, 100px, 0)
		animation: swipe-up .7s $secondary-ease-in .3s forwards

.playlistButton
	margin: 20px 0
	@include scaleWidth($suggestion-playlist-button-width)
	background: $mild-green
	opacity: 0
	transform: translate3d(0, 80px, 0)
	animation: swipe-up 1s $secondary-ease-in .4s forwards

.createNew
	display: inline-block
	color: $green
	@include responsiveHeightText($sm-title-text)
	line-height: 1.33
	font-weight: 500
	opacity: 0
	transform: translate3d(0, 80px, 0)
	animation: swipe-up 1s $secondary-ease-in .5s forwards

.playlistActionProgress
	position: absolute
	@include scaleWidth($suggestion-playlist-button-width)

.fadeOutActions
	animation: fade-out .5s $secondary-ease-in forwards
	pointer-events: none

.hasSubmitted
	.playlistButton
		animation: swipe-up .5s $secondary-ease-in .1s forwards

	.createNew
		animation: swipe-up .5s $secondary-ease-in .2s forwards

@keyframes mask-out
	to
		clip-path: inset(0px 0px 0px 0px round $medium-border-radius)

@keyframes main-cover-scale-down
	to
		transform: scale3d(1, 1, 1)

@keyframes swipe-up
	to
		opacity: 1
		transform: translate3d(0, 0, 0)

@keyframes fade-out
	from
		opacity: 1

	to
		opacity: 0
