@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.container
	transform: translateX(8vw)

.carouselGroup
	margin-bottom: 40px

.carouselItems
	display: flex

	& > div:not(:first-child)
		margin-left: 15px