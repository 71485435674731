@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.mouseGlow
    display: none
    position: fixed
    top: -15vh
    left: -15vh
    width: 30vh
    height: 30vh
    border-radius: 50%
    opacity: 1
    background: $white-glow-alt
    transition: opacity .5s $ease-both
    z-index: 5

    @include respondTo('size-sm')
        display: block

    &.passive
        opacity: 0