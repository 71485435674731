@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.listDetails
    @include responsiveModalGrid(360px, 630px, 90px)
    padding: 90px 0 65px

.title
    @include modalTitle($modal-title-text + 10)
    opacity: 0
    transform: translate3d(0, 100px, 0)
    animation: swipe-up 1s $secondary-ease-in forwards

    & > span
        transform: translate3d(0, 168%, 0)
        animation: swipe-up 1s $secondary-ease-in forwards

    &:nth-of-type(2)
        animation-delay: .1s

        & > span
            animation-delay: .1s

.listName
    color: $green

.description
    margin: 10px 0 20px
    @include responsiveHeightText($modal-description-text)
    line-height: 2
    overflow: hidden
    opacity: 0
    transform: translate3d(0, 100px, 0)
    animation: swipe-up 1s $secondary-ease-in .2s forwards

    & > span
        transform: translate3d(0, 200%, 0)
        animation: swipe-up 1s $secondary-ease-in .2s forwards

.playlistButton
    @include scaleWidth(300px)
    background: $mild-green
    opacity: 0
    transform: translate3d(0, 100px, 0)
    animation: swipe-up 1s $secondary-ease-in .3s forwards

    & > span
        transform: translate3d(0, 205%, 0)
        animation: swipe-up 1s $secondary-ease-in .4s forwards

.swipeDown
    animation: swipe-down .1s $secondary-ease-in forwards

    &:nth-of-type(2)
        animation-delay: 0s

.listActions
    position: absolute
    top: -60px
    left: 120px
    padding-left: 20px
    border-left: thin solid transparentize($gray-alt, .6)
    height: $modal-action-height
    display: flex
    align-items: center
    animation: fade-in .7s $secondary-ease-in

    & > span
        display: flex
        align-items: center
        flex-shrink: 0
        padding: 7px 20px 7px 10px
        background: transparentize($green, 1)
        @include responsiveHeightText($sm-title-text)
        line-height: 1.28
        letter-spacing: -0.75px
        font-family: 'Circular Std'
        font-weight: 500
        opacity: 0
        border-radius: $large-border-radius
        transform: translate3d(0, 30px, 0)
        transition: .7s $secondary-ease-in .1s
        animation: swipe-up .7s $secondary-ease-in forwards

        path
            transition: .7s $secondary-ease-in .1s

        &.isActive
            background: transparentize($green, .85)
            color: $green
            transition-delay: 0s

            path
                fill: $green
                transition-delay: 0s

        &:nth-child(2)
            animation-delay: .1s

        svg
            @include scaleWidthHeight(30px, 30px)
            margin-right: 8px

.exitTab
    opacity: 0
    transform: translate(0, 80px)
    transition: opacity .1s $secondary-ease-in, transform .5s $secondary-ease-in
    
.sortForm
    opacity: 1
    transform: translate(0, 0)

    & > h3
        @include responsiveHeightText($x-large-text)
        line-height: 1.25
        letter-spacing: -2.22px
        font-family: 'Circular Std'
        font-weight: 900
        opacity: 0
        transform: translate3d(0, 80px, 0)
        animation: swipe-up .7s $secondary-ease-in forwards

        & > span
            transform: translate3d(0, 80px, 0)
            animation: swipe-up .7s $secondary-ease-in forwards

    & > form
        margin-top: 15px

    legend
        opacity: 0
        transform: translate3d(0, 80px, 0)
        animation: swipe-up .7s $secondary-ease-in forwards

.orderTypes
    margin-top: 15px
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-column-gap: 15px

    & > label
        opacity: 0
        transform: translate3d(0, 80px, 0)
        animation: swipe-up .7s $secondary-ease-in forwards

        &:nth-child(2)
            animation-delay: .1s

        &:nth-child(3)
            animation-delay: .2s

.orderDirection
    margin-top: 15px
    @include scaleWidth(360px)
    opacity: 0
    transform: translate3d(0, 80px, 0)
    animation: swipe-up .7s $secondary-ease-in forwards .2s

.sortFormActions
    margin-top: 20px
    display: grid
    grid-template-columns: 1fr 1fr
    grid-column-gap: 15px
    opacity: 0
    transform: translate3d(0, 80px, 0)
    animation: swipe-up .7s $secondary-ease-in forwards .3s

.actionButton
    @include responsiveHeightText($sm-title-text)
    line-height: 1.33
    border-radius: $medium-border-radius

.applyButton
    background: $mild-green
    color: $white

.closeSortFormButton
    background: $dark-ash
    color: $green

.listShare
    opacity: 1
    transform: translate(0, 0)

    & > h3
        @include responsiveHeightText($x-large-text)
        line-height: 1.25
        letter-spacing: -2.22px
        font-family: 'Circular Std'
        font-weight: 900
        opacity: 0
        transform: translate3d(0, 80px, 0)
        animation: swipe-up .7s $secondary-ease-in forwards

        & > span
            transform: translate3d(0, 80px, 0)
            animation: swipe-up .7s $secondary-ease-in forwards

    & > p
        margin: 10px 0 30px
        color: $mild-gray
        @include responsiveHeightText($regular-text)
        line-height: 1.31
        font-weight: 500
        opacity: 0
        transform: translate3d(0, 80px, 0)
        animation: swipe-up .7s $secondary-ease-in forwards

        & > span
            transform: translate3d(0, 80px, 0)
            animation: swipe-up .7s $secondary-ease-in forwards

.shareSource
    text-transform: lowercase

.sharePlatform
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-column-gap: 15px

.disableShare
    opacity: 0.7
    pointer-events: none

.shareCard
    opacity: 0
    transform: translate3d(0, 80px, 0)
    animation: swipe-up .7s $secondary-ease-in forwards

    &:nth-child(2)
        animation-delay: .1s

    &:nth-child(3)
        animation: swipe-disabed-up .7s $secondary-ease-in .2s forwards

.closeListShareButton
    margin-top: 20px
    width: 100%
    background: $dark-ash
    color: $green
    opacity: 0
    transform: translate3d(0, 100px, 0)
    animation: swipe-up .7s $secondary-ease-in .2s forwards
        
.listNav
    height: 90px
    @include scaleWidth(600px, 30px)
    display: flex
    justify-content: center
    align-items: flex-end
    padding-bottom: 27px
    position: absolute
    top: 0
    z-index: 10
    animation: fade-in .7s $secondary-ease-in

.navBar
    position: absolute
    top: 0
    @include scaleWidth($top-list-nav-label-width)
    height: 3px
    background: $green
    transform: translate3d(0, 0, 0)
    transition: .7s $secondary-ease-in
    animation: nav-bar-in .8s

    &.firstActive
        transform: translate3d(calc(-100% - 20px), 0, 0)

    &.lastActive
        transform: translate3d(calc(100% + 20px), 0, 0)

.rangeOption
    margin: 0 10px
    display: inline-block
    @include scaleWidth($top-list-nav-label-width)
    text-align: center
    @include responsiveHeightText($regular-text)
    line-height: 1.25
    font-family: 'Circular Std'
    font-weight: 700
    opacity: 0
    transform: translate3d(0, 30px, 0)
    transition: color .5s $secondary-ease-in
    animation: swipe-up .7s $secondary-ease-in forwards

    &:nth-child(3)
        animation-delay: .1s

    &:nth-child(4)
        animation-delay: .2s

.activeRangeOption
    color: $green

.listItems
    display: grid
    grid-template-columns: repeat(3, auto)
    grid-gap: 15px
    position: relative

.topListFade
    position: absolute
    top: -90px
    left: 0
    height: calc(90px + 10vh)
    @include scaleWidth(600px, 30px)
    background: linear-gradient(#1A1B1C 0%, rgba(26,27,28,0) 100%)
    opacity: 0
    z-index: 5
    pointer-events: none
    transition: opacity .4s $primary-ease

    &.isActive
        opacity: 1

@keyframes fade-in
    from
        opacity: 0

    to
        opacity: 1

@keyframes swipe-up
    to
        opacity: 1
        transform: translate3d(0, 0, 0)

@keyframes swipe-disabed-up
    to
        opacity: 0.35
        transform: translate3d(0, 0, 0)

@keyframes swipe-down
    from
        opacity: 1
        transform: translate3d(0, 0, 0)

    to
        opacity: 0
        transform: translate3d(0, 80px, 0)

@keyframes nav-bar-in
    0%
        transform: translate3d(calc(-100% - 20px), 0, 0) scale3d(0, 1, 1)

    12.5%
        animation-timing-function: $secondary-ease-in
        transform: translate3d(calc(-100% - 20px), 0, 0) scale3d(0, 1, 1)
        transform-origin: left

    100%
        transform: translate3d(calc(-100% - 20px), 0, 0) scaleX(1, 1, 1)
        transform-origin: left