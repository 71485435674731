@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.actionProgress
	@include scaleHeight($modal-btn-height)
	background: $dark-ash
	@include fullCenter
	padding: 0
	color: $green
	@include responsiveHeightText($sm-title-text)
	font-weight: 500
	line-height: 1.35
	letter-spacing: -0.5px
	font-family: 'Circular Std'
	border: thin solid $green
	border-radius: $medium-border-radius
	transform: translate(0, 15px)
	z-index: 10
	animation: fade-in .5s $secondary-ease-in forwards

	& > div:not(.actionError)
		@include fullCenter

	svg
		margin-right: 5px
		@include scaleWidthHeight(30px, 30px)

.exitProgress
	animation: fade-out .5s $secondary-ease-in forwards

.hasError
	@include scaleHeight($playlist-progress-error-height)
	@include scaleMinWidth($playlist-progress-error-width)
	max-width: 100%
	border-color: $red
	transition: .5s $secondary-ease-in

.saving > svg
	@include scaleWidthHeight(34px, 34px)
	animation: spinner-spin 1.6s linear infinite

.exitSaving
	animation: fade-out .2s $secondary-ease-in forwards

.saved
	animation: fade-in .5s $secondary-ease-in forwards

.actionError
	width: 100%
	display: flex
	align-items: flex-start
	padding: 20px 20px
	animation: fade-in .5s $secondary-ease-in forwards

	svg
		margin-right: 10px

	p
		color: $red
		@include responsiveHeightText($sm-title-text)
		line-height: 1.67
		font-weight: 500

		span
			color: $white

@keyframes fade-in
	from
		opacity: 0

	to
		opacity: 1

@keyframes fade-out
	from
		opacity: 1

	to
		opacity: 0

@keyframes spinner-spin
	from
		transform: rotate(-1080deg)

	to
		transform: rotate(0deg)
