@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.label
	position: relative
	display: flex
	justify-content: center
	align-items: center
	width: 100%
	padding: 10px 5px
	background: $dark-ash
	border: $regular-border solid $dark-ash
	border-radius: $medium-border-radius
	@include responsiveHeightText($sm-title-text)
	line-height: 1.67
	letter-spacing: -0.79px
	font-family: 'Circular Std'
	font-weight: 500
	cursor: none
	transition: .7s $primary-ease

	path
		transition: .7s $primary-ease

	&.withCardIcon
		flex-direction: column
		justify-content: space-between
		@include scaleHeight($card-radio-height)
		padding: 20px - $regular-border 5px 15px - $regular-border

		& > svg
			@include scaleWidthHeight(50px, 50px)

	&.isDisabled
		opacity: .3

	input
		position: absolute
		z-index: -10
		opacity: 0

.checkedLabel
	background: transparentize($green, .85)
	border-color: $green
	color: $green

	path
		fill: $green