@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.spiderChart
	background: transparent
	@include innerGridContainer(6)
	z-index: 20

	&:not(.isPassive)
		.chartTip
			&:nth-child(2)
				animation: tip-one-in 1s

				.columnIcon
					animation: tip-one-icon-in 1s

			&:nth-child(3)
				animation: tip-two-in 1.05s

				.columnIcon
					animation: tip-two-icon-in 1.15s

			&:nth-child(4)
				animation: tip-three-in 1.1s

				.columnIcon
					animation: tip-three-icon-in 1.2s

			&:nth-child(5)
				animation: tip-four-in 1.15s

				.columnIcon
					animation: tip-four-icon-in 1.25s

			&:nth-child(6)
				animation: tip-five-in 1.2s

				.columnIcon
					animation: tip-five-icon-in 1.3s

			&:nth-child(7)
				animation: tip-six-in 1.25s

				.columnIcon
					animation: tip-six-icon-in 1.35s

.isPassive
	.chartVector
		& > g:nth-child(2) > g

			&:nth-child(1), &:nth-child(2), &:nth-child(3)
				transform: scale(0)

				& > use
					&:first-child
						fill: $green

			&:nth-child(4) use, &:nth-child(5) use, &:nth-child(6) use
				fill: $green
				transform: scale(0)

			&:nth-child(7)
				transform: scale(0)

				& > use
					&:first-child
						fill: $green

					&:last-child
						stroke: $green

		& > g:nth-child(3)
			opacity: 0

	.chartTip
		clip-path: circle(0)

.spiderChartContent
	@include fullAbsolute
	z-index: 20

.chartVector
	width: 60vh
	height: 60vh

	& > g:nth-child(2) > g
		transition: transform .7s $secondary-ease-in

		&:nth-child(1), &:nth-child(2), &:nth-child(3)
			transform: scale(1)
			transform-origin: center

			& > use:first-child
				transition: .7s $secondary-ease-in

		&:nth-child(2)
			transition-delay: .1s

			& > use:first-child
				transition-delay: .1s

		&:nth-child(3)
			transition-delay: .2s

			& > use:first-child
				transition-delay: .2s

		&:nth-child(4) use, &:nth-child(5) use, &:nth-child(6) use
			transform: scale(1)
			transform-origin: center
			transition: .7s $secondary-ease-in

		&:nth-child(7)
			transform: scale(1)
			transform-origin: center
			transition-delay: .3s

			& > use
				transition: .7s $secondary-ease-in .3s

	& > g:nth-child(3)
		opacity: 1
		transition: 1.4s $secondary-ease-in .4s

.chartTips
	@include fullAbsolute
	transform: translate(50%, 50%)

.chartTipsEllipse
	@include fullAbsolute
	transform: translate(-50%, -50%)
	border-radius: 50%

.chartTip
	position: absolute
	background: $black
	border-radius: 50%
	@include scaleWidth($spider-chart-tip-dim, 5px)
	@include scaleHeight($spider-chart-tip-dim, 5px)
	clip-path: circle(30px)
	transform: translate(-50%, -50%) scale(1)
	transition: all .5s $secondary-ease-in
	z-index: 10

	&:hover
		clip-path: circle(calc(50% - 1px))
		z-index: 20

		.chartTipContent
			clip-path: circle(calc(50% - 1px))
			z-index: 20

		.columnIcon
			transform: translate3d(0, 0, 0)

		.columnLabel span
			opacity: 1
			transform: translate3d(0, 0, 0)
			transition: .6s $secondary-ease-in .1s

		.tipValueLabel span
			opacity: 1
			transform: translate3d(0, 0, 0)
			transition: .6s $secondary-ease-in .15s

		.tipTotal span
			opacity: 1
			transform: translate3d(0, 0, 0)
			transition: .6s $secondary-ease-in .2s

		.columnInfo
			opacity: 1
			transform: translate3d(0, 0, 0)
			transition: .4s $ease-both, opacity .6s $secondary-ease-in .25s, transform .6s $secondary-ease-in .25s

		.columnBgIcon
			opacity: .1
			transform: translate3d(-35%, -35%, 0) scale3d(1, 1, 1)
			transition: 1s $secondary-ease-in

.chartTipContent
	position: absolute
	top: 5px
	right: 5px
	bottom: 5px
	left: 5px
	background: $mild-green
	border-radius: 50%
	clip-path: circle(25px)
	padding: 15% 1% 10px
	transition: .5s $secondary-ease-in
	z-index: 10
	overflow: hidden

.columnIcon
	position: absolute
	top: 40px
	left: 70px
	top: 50%
	left: 50%
	opacity: 1
	transform: translate(calc(-50% + 0.5px), calc(-50% + 2px)) scale(1)
	transition: .5s $secondary-ease-in
	z-index: 5

	&.columnIconWillChange
		will-change: transform

.chartTipTitle
	@include fullCenter

.columnLabel
	margin-left: 30px
	@include responsiveHeightText($spider-chart-label-text)
	line-height: 1.25
	height: 2.5em
	@include scaleMaxWidth($spider-chart-tip-dim * .36)
	@include fullCenter
	hyphens: maual
	z-index: 5

	span
		display: inline-block
		opacity: 0
		transform: translate(0, 160px)
		transition: .3s $secondary-ease-in, transform .2s $secondary-ease-in .3s

.tipValueLabel
	position: relative
	@include responsiveHeightText($spider-chart-value-text)
	font-family: 'Circular Std'
	font-weight: 700
	letter-spacing: -2.33px
	line-height: 1.03
	text-align: center
	z-index: 5

	span
		display: inline-block
		opacity: 0
		transform: translate(0, 160px)
		transition: .3s $secondary-ease-in, transform .2s $secondary-ease-in .3s

.tipTotal
	position: relative
	margin-top: 5px
	@include responsiveHeightText($regular-text)
	font-family: 'Circular Std'
	font-weight: 700
	letter-spacing: -0.36px
	line-height: 1.25
	text-align: center
	opacity: .5
	z-index: 5

	span
		display: inline-block
		opacity: 0
		transform: translate(0, 160px)
		transition: .3s $secondary-ease-in, transform .2s $secondary-ease-in .3s

.columnInfo
	position: absolute
	top: 0
	right: -40%
	bottom: -80%
	left: -40%
	display: flex
	justify-content: center
	background: $white
	padding: 0 60%
	text-align: center
	@include responsiveHeightText($regular-text)
	opacity: 0
	transform: translate(0, 10px)
	transition: opacity .3s linear, transform .2s $secondary-ease-in .3s
	z-index: 10
	clip-path: circle(10px at 50% calc(55% - 1.2em - 10px))

	p
		height: 54%
		display: flex
		align-items: center
		@include responsiveHeightText($sm-title-text)
		line-height: 1.33
		font-weight: 500
		opacity: 0
		transform: translate(0, 160px)
		transition: .4s $ease-both

.infoIcon
	position: absolute
	top: calc(55% - 1.2em)
	left: 50%
	transform: translate(-50%, -100%)
	display: inline-flex
	justify-content: center
	padding: 4px 0
	width: 20px
	height: 20px
	border-radius: 50%

	path
		transition: .4s $ease-both

.columnInfoHotspot
	position: absolute
	width: 40px
	height: 40px
	bottom: calc(1.2em - 10px)
	left: 50%
	transform: translateX(-50%)
	z-index: 20

	&:hover
		& + .columnInfo
			background: $dark-ash
			clip-path: circle(50%)
			transition: .5s $primary-ease

			p
				opacity: 1
				transform: translate3d(0, 0, 0)
				transition: .7s $secondary-ease-in

			.infoIcon path
				fill: $green
				transition: .5s $secondary-ease-in

.columnBgIcon
	position: absolute
	top: 0
	left: 0
	opacity: 0
	transform: translate(-35%, -35%) scale(2)
	transition: .5s $secondary-ease-in

	svg
		@include scaleWidthHeight($spider-chart-tip-bg-icon-dim, $spider-chart-tip-bg-icon-dim)

@keyframes tip-one-in
	0%
		clip-path: circle(0)

	30%
		clip-path: circle(0)
		animation-timing-function: $secondary-ease-in

@keyframes tip-two-in
	0%
		clip-path: circle(0)

	33%
		clip-path: circle(0)
		animation-timing-function: $secondary-ease-in

@keyframes tip-three-in
	0%
		clip-path: circle(0)

	36%
		clip-path: circle(0)
		animation-timing-function: $secondary-ease-in

@keyframes tip-four-in
	0%
		clip-path: circle(0)

	39%
		clip-path: circle(0)
		animation-timing-function: $secondary-ease-in

@keyframes tip-five-in
	0%
		clip-path: circle(0)

	42%
		clip-path: circle(0)
		animation-timing-function: $secondary-ease-in

@keyframes tip-six-in
	0%
		clip-path: circle(0)

	44%
		clip-path: circle(0)
		animation-timing-function: $secondary-ease-in

@keyframes tip-one-icon-in
	0%
		opacity: 0
		transform: translate3d(calc(-50% + 0.5px), calc(-50% + 2px), 0) scale3d(0, 0, 0)

	30%
		opacity: 0
		transform: translate3d(calc(-50% + 0.5px), calc(-50% + 2px), 0) scale3d(0, 0, 0)
		animation-timing-function: $secondary-ease-in

@keyframes tip-two-icon-in
	0%
		opacity: 0
		transform: translate3d(calc(-50% + 0.5px), calc(-50% + 2px), 0) scale3d(0, 0, 0)

	39%
		opacity: 0
		transform: translate3d(calc(-50% + 0.5px), calc(-50% + 2px), 0) scale3d(0, 0, 0)
		animation-timing-function: $secondary-ease-in

@keyframes tip-three-icon-in
	0%
		opacity: 0
		transform: translate3d(calc(-50% + 0.5px), calc(-50% + 2px), 0) scale3d(0, 0, 0)

	42%
		opacity: 0
		transform: translate3d(calc(-50% + 0.5px), calc(-50% + 2px), 0) scale3d(0, 0, 0)
		animation-timing-function: $secondary-ease-in

@keyframes tip-four-icon-in
	0%
		opacity: 0
		transform: translate3d(calc(-50% + 0.5px), calc(-50% + 2px), 0) scale3d(0, 0, 0)

	44%
		opacity: 0
		transform: translate3d(calc(-50% + 0.5px), calc(-50% + 2px), 0) scale3d(0, 0, 0)
		animation-timing-function: $secondary-ease-in

@keyframes tip-five-icon-in
	0%
		opacity: 0
		transform: translate3d(calc(-50% + 0.5px), calc(-50% + 2px), 0) scale3d(0, 0, 0)

	46%
		opacity: 0
		transform: translate3d(calc(-50% + 0.5px), calc(-50% + 2px), 0) scale3d(0, 0, 0)
		animation-timing-function: $secondary-ease-in

@keyframes tip-six-icon-in
	0%
		opacity: 0
		transform: translate3d(calc(-50% + 0.5px), calc(-50% + 2px), 0) scale3d(0, 0, 0)

	48%
		opacity: 0
		transform: translate3d(calc(-50% + 0.5px), calc(-50% + 2px), 0) scale3d(0, 0, 0)
		animation-timing-function: $secondary-ease-in
