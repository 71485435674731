@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.dropdown
	position: absolute
	padding:  10px 0
	background: $mild-dark-ash-alt
	box-shadow: 0 40px 99px 0 $pure-black
	clip-path: inset(0px 100% 100% 0px round $regular-border-radius)
	transform: translate(0, 30px)
	animation: mask-out .5s $secondary-ease-in forwards

.reverseMask
	clip-path: inset(0px 0px 100% 100% round $regular-border-radius)

@keyframes mask-out
	to
		transform: translate(0, 0)
		clip-path: inset(0px 0px 0px 0px round $regular-border-radius)