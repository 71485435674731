.container
	position: absolute
	width: 100vw
	height: 100vh
	overflow: hidden

.noTransiton *
	transition: none !important

	&:before, &:after
		transition: none !important