@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.container
    @include gridContainer(19)

.isPassive
    .topGenreCaption, .topGenreSubcaption
        transform: translate(0, 100px)

        & > span
            transform: translate(0, 100%)
            opacity: 0

.sectionIcon
    grid-area: 3 / 3 / 4 / 4

.userSummary
    @include innerGridContainer(7)
    grid-area: 4 / 3 / 10 / 10
    z-index: 20
    pointer-events: none

.userSummaryContent
    @include fullAbsolute
    z-index: 20

.topGenreCaption
    @include sectionCaptionText($section-caption-text - 10)
    transform: translate(0, 0)
    transition: 1s $secondary-ease-in .1s

    & > span
        display: inline-block
        opacity: 1
        transform: translate(0, 0)
        transition: 1s $secondary-ease-in .1s

.topGenreSubcaption
    @include sectionSubcaptionText
    transform: translate(0, 0)
    transition: 1s $secondary-ease-in .2s

    & > span
        display: inline-block
        opacity: 1
        transform: translate(0, 0)
        transition: 1s $secondary-ease-in .2s

.whiteText
    color: $white

.greenCaption
    color: $green

.genreBarContainer
    @include innerGridContainer(1)
    z-index: 20

.genreBarContent
    @include fullAbsolute
    z-index: 20

    &.isBarPassive
        .genreBar
            clip-path: inset(100% 0 0 0 round $medium-border-radius)

            &:before
                opacity: 0

        .genreBarLevel
            transform: scaleY(0)
            clip-path: inset(0 0 0 0 round 0)

        .genreBarLevelText
            opacity: 0

        .genreName > span
            transform: translateY(10vh)

    &:not(.isBarPassive)
        .genreBar
            animation: bar-scale-up .7s $secondary-ease-in

            &:before
                animation: show-up .7s $secondary-ease-in

        .genreBarLevel
            animation: bar-level-scale-up 1s $secondary-ease-in .1s

        .genreBarLevelText
            animation: show-up .4s $secondary-ease-in .7s

        .genreName > span
            animation: genre-name-in .7s $secondary-ease-in .3s          

.genreBar
    position: relative
    width: 100%
    height: 50vh
    transform-origin: bottom
    clip-path: inset(0 0 0 0 round $medium-border-radius)
    overflow: hidden

    @include before
        @include fullAbsolute
        background: $light-gray

.genreBarLevel
    position: absolute
    right: 0
    bottom: 0
    left: 0
    clip-path: inset(0 0 0 0 round #{$medium-border-radius + 3} #{$medium-border-radius + 3} 0 0)
    transform: translate(0, 0)
    transform-origin: bottom
    transition: 0s ease .1s

.genreBarLevelText
    position: absolute
    top: 0
    right: 0
    left: 0
    height: calc(10vh * 0.78)
    @include fullCenter
    color: $ash
    @include responsiveHeightText($bar-level-text)
    font-family: 'Circular Std'
    line-height: 1.27
    z-index: 10
    transition: opacity 0s ease .7s

.genreName
    position: absolute
    left: -3vh
    right: -3vh
    margin-top: .85em
    min-height: calc(10vh - .85em)
    text-align: center
    @include responsiveHeightText($bar-name-text)
    font-family: 'Circular Std'
    font-weight: 700
    line-height: 1.25
    letter-spacing: -0.7px
    text-transform: capitalize
    overflow: hidden

    & > span
        display: inline-block
        transition: transform 0s ease .3s

.barOne
    grid-area: 3 / 11 / 10 / 12

.barTwo
    grid-area: 3 / 13 / 10 / 14

.barThree
    grid-area: 3 / 15 / 10 / 16

.barFour
    grid-area: 3 / 17 / 10 / 18

.barFive
    grid-area: 3 / 19 / 10 / 20


@keyframes bar-scale-up
    0%
        clip-path: inset(100% 0 0 0 round $medium-border-radius)

    100%
        clip-path: inset(0 0 0 0 round $medium-border-radius)

@keyframes show-up
    0%
        opacity: 0

    100%
        opacity: 1

@keyframes bar-level-scale-up
    0%
        transform: scaleY(0)
        clip-path: inset(0 0 0 0 round 0)

    100%
        transform: scaleY(1)
        clip-path: inset(0 0 0 0 round #{$medium-border-radius + 3} #{$medium-border-radius + 3} 0 0)

@keyframes genre-name-in
    0%
        transform: translateY(10vh)

    100%
        transform: translateY(0)