@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.loader
    @include fullAbsolute
    @include fullCenter

    @include after
        position: fixed
        top: 0
        bottom: 0
        width: 100vw
        transition: .8s $ease-both .3s
        z-index: 100

    &:after
        left: 0
        background: $black-alt

    &.loadComplete
        &:after
            left: 200vw

.loaderProgressBar
    width: $loader-width
    height: $loader-height
    border-radius: $regular-border-radius
    background: transparentize($white, .6)
    position: relative
    z-index: 130

.loadProgress
    position: absolute
    top: -1px
    right: 0
    bottom: -1px
    left: 0
    background: $white
    border-radius: $regular-border-radius
    transition: .5s $ease-both
    transform-origin: left
    animation: loader-progress-animation .7s $ease-both

@keyframes loader-progress-animation
    from
        transform: scaleX(0)