@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.tabSwitch
	position: relative
	display: flex
	height: $tab-switch-height
	background: $dark-ash
	opacity: 1
	clip-path: inset(0px 0px 0px 0px round $medium-border-radius)
	transition: .7s $secondary-ease-in

	@include before
		position: absolute
		top: 0
		right: 50%
		bottom: 0
		left: 0
		background: $dark-green
		transform: translate3d(0, 0, 0)

.isPassive
	opacity: 0
	clip-path: inset(0px 50% 0px 50% round $medium-border-radius)

	.tab
		transform: translate(0, 40px)

.tabTwoActive:before
	transform: translate3d(100%, 0, 0)

.tab
	position: relative
	@include fullCenter
	width: 50%
	flex-basis: 50%
	font-size: $sm-title-text
	line-height: 1.33
	font-weight: 500
	user-select: none
	transform: translate(0, 0)
	z-index: 5
	transition: .7s $secondary-ease-in