@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.artistPill
	margin-bottom: 15px
	display: flex
	align-items: center
	background: $mild-dark-ash
	border-radius: $medium-border-radius
	overflow: hidden
	transform: translateZ(0)

	h3
		width: calc(100% - 105px)
		flex-basis: calc(100% - 105px)
		padding: 0 15px
		font-size: $track-pill-title-text
		font-family: 'Circular Std'
		line-height: 1.27
		letter-spacing: -0.73px
		font-weight: 700
		@include ellipsis

	a
		display: inline-flex
		align-items: center

.artistCoverImage
	width: $artist-pill-cover-dim
	height: $artist-pill-cover-dim
	background-size: cover
	background-position: center
	position: relative

	@include before
		@include fullAbsolute
		background: transparentize($light-gray-alt, .3)
		opacity: 0
		transition: .5s $ease-both