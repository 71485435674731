@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.selectDropdown
	position: relative

.selected
	display: flex
	justify-content: space-between
	align-items: center
	@include scaleHeight($select-input-height)
	padding: 5px 18px 5px 20px
	background: $dark-ash
	border-radius: $medium-border-radius


	& > svg
		transform-origin: right center
		@include scaleSvg

.selectedValue
	display: inline-block
	width: calc(100% - 90px)
	color: $green
	@include responsiveHeightText($sm-title-text)
	line-height: 1.28
	letter-spacing: -0.5px
	font-family: 'Circular Std'
	font-weight: 500
	@include ellipsis

.selectedInfo
	margin-top: 10px
	color: $mild-gray
	@include responsiveHeightText($regular-text)
	line-height: 1.32
	font-weight: 400