@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.artistCard
	position: relative
	width: calc((84vw - 15px) / 2)
	height: calc(((84vw - 15px) / 2) * 1.27)
	border-radius: $medium-border-radius
	transform: translate(0, 0)
	transition: .7s $secondary-ease-in
	overflow: hidden
	
	&.featured
		grid-area: 1 / 1 / 2 / 3
		width: 84vw
		height: calc((84vw) * 1.16)

		.artistCoverImage
			width: 84vw
			height: calc((84vw) * 1.16)

		.spotifyLink
			top: 15px
			right: 15px

		.artistCardTitle
			padding: 0 20px 20px 20px

			h2
				font-size: $mobile-card-name-text
				line-height: 1.33
				letter-spacing: -1px

	& > div
		position: relative
		width: 100%
		height: 100%
		clip-path: inset(1px 0 1px 0 round $medium-border-radius)
		transition: .7s $secondary-ease-in

		@include after
			@include fullAbsolute
			opacity: 1
			background: linear-gradient(223.83deg, rgba(17,17,18,0) 0%, rgba(17,17,18,0.6) 100%)
			transition: .7s $secondary-ease-in
			z-index: 10

.cardMaskOut
	.artistCoverImage
		transition: 1s $secondary-ease-in

	.artistCardTitle
		h3
			transition: .7s $secondary-ease-in .2s

		h2
			transition: .7s $secondary-ease-in .3s


.isPassive
	transform: translate(0, 200px)
	opacity: 0

	&.featured
		.artistCoverImage
			transform: scale3d(2.54, 2.54, 2.54)

		.artistCardTitle
			h2, h3
				transform: translate(0, 90px)


	&.cardMaskOut
		opacity: 1

		& > div
			clip-path: inset(1px 0 100% 0 round $medium-border-radius)

		&:after
			opacity: 0

		.artistCoverImage
			transform: scale(2.1)

		.artistCardTitle
			h2, h3
				transform: translate(0, 70px)

		.spotifyLink
			opacity: 0

	.artistCoverImage
		transform: scale(2)

	.artistCardTitle
		h3
			transform: translate(0, 96px)

		h2
			transform: translate(0, 177%)

.artistCoverImage
	position: absolute
	top: 0
	left: 0
	width: calc((100vw - 75px) / 2)
	height: calc(((100vw - 75px) / 2) * 1.27)
	background-size: cover
	background-position: center
	transform: scale(1)
	transition: .7s $secondary-ease-in

.spotifyLink
	position: absolute
	top: 10px
	right: 10px
	opacity: 1
	transform: translateZ(0)
	transition: .7s $secondary-ease-in
	z-index: 15

.artistCardTitle
	position: absolute
	bottom: 0
	width: 100%
	padding: 0 15px 15px 15px
	z-index: 15

	h3
		margin: 0
		font-size: $regular-text
		line-height: 1.625
		font-weight: 300
		transform: translate(0, 0)
		transition: .7s $secondary-ease-in .1s

	h2
		margin: 0
		font-size: $mobile-card-name-text-sm
		line-height: 1.27
		letter-spacing: -0.73px
		font-family: 'Circular Std'
		font-weight: 700
		transform: translate(0, 0)
		transition: .7s $secondary-ease-in .2s

		& > span
			display: flex
			flex-direction: column
