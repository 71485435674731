@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.artistPill
	display: inline-flex
	align-items: center
	background: $light-gray-alt
	margin: 0 15px 15px 0
	border-radius: $medium-border-radius
	overflow: hidden
	opacity: 0
	transform: translate3d(0, 40px, 0)
	transition: .7s $secondary-ease-in

	&:not(.isPassive)
		opacity: 1
		transform: translate(0, 0)
		
		h3, a
			transform: translate(0, 0)

	&:hover
		.artistCoverImage
			&:before
				transform: translateZ(0)
				opacity: 1

			& > span
				opacity: 1
				transform: translate3d(0, 0, 0)

	h3
		margin: 0 18px
		max-width: 40vh
		color: $white
		@include responsiveHeightText($btn-text-md)
		line-height: 1.25
		font-family: 'Circular Std'
		font-weight: 700
		@include ellipsis
		transform: translate3d(0, 50px, 0)
		transition: .7s $secondary-ease-in .1s

	a
		display: inline-flex
		margin-right: 2px
		transform: translate3d(0, 50px, 0)
		transition: .7s $secondary-ease-in

		@include respondTo('height-md')
			margin-right: 5px

		@include respondTo('height-lg')
			margin-right: 11px

		@include respondTo('height-xl')
			margin-right: 18px

		span
			transform-origin: center left
			@include scaleSvg


.artistCoverImage
	@include scaleWidthHeight($artist-pill-cover-dim, $artist-pill-cover-dim)
	background-size: cover
	background-position: center
	position: relative

	@include before
		@include fullAbsolute
		background: transparentize($light-gray-alt, .3)
		opacity: 0
		transition: .5s $ease-both

	& > span
		@include fullAbsolute
		@include fullCenter
		opacity: 0
		transform: translate(0, 10px)
		transition: .5s $ease-both

	svg
		@include scaleSvg