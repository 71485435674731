@import '../../sass/abstracts/variables'
@import '../../sass/abstracts/mixins.sass'

.titleCard
	display: inline-flex
	align-items: center
	background: $light-gray-alt
	margin-bottom: 15px
	padding: 20px 30px 20px 20px
	border-radius: $medium-border-radius
	opacity: 0
	transform: translate3d(0, 40px, 0)
	overflow: hidden
	transition: .7s $secondary-ease-in

	&:not(.isPassive)
		opacity: 1
		transform: translate(0, 0)

		.cardTitle, .cardContent
			transform: translate(0, 0)

	& > div
		min-width: 130px

.cardIcon
	@include scaleWidth(50px)
	transform: translateY(3px)

.cardTitle
	margin: 0
	color: $mild-gray
	@include responsiveHeightText($regular-text)
	line-height: 1.3
	transform: translate3d(0, 110px, 0)
	transition: .7s $secondary-ease-in

.cardContent
	@include responsiveHeightText($title-card-text)
	font-family: 'Circular Std'
	font-weight: 700
	line-height: 1.25
	transform: translate3d(0, 110px, 0)
	transition: .7s $secondary-ease-in .1s