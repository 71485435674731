@import '../../sass/abstracts/variables'
@import '../../sass/abstracts/mixins.sass'

.btn
    display: inline-block
    text-align: center
    vertical-align: middle
    user-select: none
    background: $black-alt
    @include responsiveHeightText($btn-text)
    font-weight: 500
    line-height: 1.35
    letter-spacing: -0.5px
    color: $green
    padding: 20px * $h-sm-multiplier
    border: 0
    border-radius: $regular-border-radius * $h-sm-multiplier
    box-shadow: 0 40px 90px 0 rgba(0,0,0,0)

    @include respondTo('size-sm')
        cursor: none

    @include respondTo('height-md')
        padding: 20px * $h-md-multiplier
        border-radius: $regular-border-radius * $h-md-multiplier

    @include respondTo('height-lg')
        padding: 20px * $h-lg-multiplier
        border-radius: $regular-border-radius * $h-lg-multiplier

    @include respondTo('height-xl')
        padding: 20px
        border-radius: $regular-border-radius

    &.btnMd
        @include responsiveHeightText($btn-text-md)

    &.btnSm
        padding: 14px * $h-sm-multiplier 20px
        border-radius: calc(#{$regular-border-radius} / 2)
        @include responsiveHeightText($regular-text)

        @include respondTo('height-md')
            padding: 14px * $h-md-multiplier 20px

        @include respondTo('height-lg')
            padding: 14px * $h-lg-multiplier 20px

        @include respondTo('height-xl')
            padding: 14px 20px

    &.btnXs
        padding: 11px * $h-sm-multiplier 20px
        border-radius: calc(#{$regular-border-radius} / 2)
        @include responsiveHeightText($medium-text)

        @include respondTo('height-md')
            padding: 11px * $h-md-multiplier 20px

        @include respondTo('height-lg')
            padding: 11px * $h-lg-multiplier 20px

        @include respondTo('height-xl')
            padding: 11px 20px

    &:hover
        outline: 0

    &:focus
        outline: 0

    &:disabled
        opacity: .6
        cursor: not-allowed

    &.btnBlock
        width: 100%

.neoBtn
    position: relative
    background: $mild-green
    color: $white
    overflow: hidden
    border: $regular-border solid $mild-green

    &.neoActive
        color: $green
        transition: .5s $secondary-ease-in

        svg > g > g:last-child > g > path
            fill: $green

    &.neoOut
        transition: .5s $secondary-ease-in

    svg > g > g:last-child > g > path
        transition: .5s $secondary-ease-in

    & > span:last-child
        margin: 0
        transform: none
        z-index: -1

        & > span
            display: inline-block
            width: 100vh
            height: 100vh
            background: $black
            border-radius: 50%

.hoverWipe
    @include fullAbsolute

    &.swipeIn
        & > span
            transition: .5s $secondary-ease-in

    &.swipeOut
        & > span
            transition: .6s $secondary-ease-in

.modalOpen
    @include innerGridContainer(1)
    z-index: 20

.modalOpenContent
    @include fullAbsolute
    @include fullCenter
    z-index: 20
    flex-direction: column
    font-family: "Circular Std"
    @include responsiveHeightText($regular-text)
    line-height: 1.25

    @include before
        @include fullAbsolute
        background: $light-gray
        border-radius: $small-border-radius
        opacity: 0
        transition: .7s $secondary-ease-in

    &:not(.isPassive)
        &:before
            opacity: 1

        .fifty
            opacity: 1

        .expandCorner
            opacity: 1

            &:nth-child(1)
                transform: translate(-10px, 10px)

            &:nth-child(2)
                transform: translate(10px, 10px)

            &:nth-child(3)
                transform: translate(10px, -10px)

            &:nth-child(4)
                transform: translate(-10px, -10px)

        &:hover
            .expandCorner
                transform: translate3d(0, 0, 0)
                transition: .7s $secondary-ease-in

            path
                fill: $green-alt
                transition: .7s $secondary-ease-in

            .top
                opacity: 1
                transform: translate(0, 0)
                transition: .7s $secondary-ease-in

            .fifty
                transform: translate(0, 0)
                transition: .7s $secondary-ease-in

    svg
        position: absolute
        @include scaleSvg

.expandCorner
    opacity: 0
    transition: .3s $primary-ease

    &.expandIn
        transition: .7s $ease-out-back .1s

    &:nth-child(1)
        transform: translate(-20px, 20px)

    &:nth-child(2)
        transform: translate(20px, 20px)

    &:nth-child(3)
        transform: translate(20px, -20px)

    &:nth-child(4)
        transform: translate(-20px, -20px)

    path
        transition: .3s $primary-ease

.top
    opacity: 0
    transform: translate(0, -0.625em)
    transition: .3s $primary-ease

.fifty
    transform: translate(0, -0.625em)
    opacity: 0
    transition: opacity .7s $secondary-ease-in .6s, transform .3s $primary-ease

.playlistButton, .spotifyButton
    margin-top: 10px
    padding: 0
    width: 100%
    @include scaleHeight($modal-btn-height)
    border-radius: $medium-border-radius
    color: $white
    @include responsiveHeightText($sm-title-text)
    font-family: 'Circular Std'
    @include fullCenter
    overflow: hidden

    & > span
        @include fullCenter
        pointer-events: none

        & > svg, & > span
            margin-right: 5px
            @include scaleSvg
            transform-origin: right

.playlistButton
    background: $light-gray

.spotifyButton
    background: $mild-green

.mobileModalOpen
    @include fullCenter
    flex-direction: column
    height: $mobile-list-action-height
    background: $dark-ash-alt
    border-radius: $medium-border-radius

    & > svg
        position: absolute

    span
        font-size: $regular-text
        line-height: 1.25
        font-family: "Circular Std"
        font-weight: 500

.radialIconButton
    margin: 0 9px
    display: inline-flex
    justify-content: center
    align-items: center
    width: $radial-icon-button-dim
    height: $radial-icon-button-dim
    background: transparentize($white, .9)
    border-radius: 50%
    clip-path: circle(50%)
    transform: translate3d(0, 0, 0)
    transition: .7s $secondary-ease-in

    &.isActive
        background: transparentize($green, .9)

        path
            fill: $green

    &.isPassive
        clip-path: circle(0%)
        transform: translate3d(0, 200px, 0)

        &.isActive
            background: $deep-green-alt

        svg
            transform: translate(0, 44px)

    svg
        width: 30px
        height: 30px
        transform: translate(0, 0)
        transition: .7s $secondary-ease-in .1s

        path
            transition: .7s $secondary-ease-in
