@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.artistDetails
    @include responsiveModalGrid(360px, 630px, 90px)
    padding: 90px 0 65px

.title
    @include modalTitle
    opacity: 0
    transform: translate3d(0, 40px, 0)
    animation: swipe-up .7s $secondary-ease-in .3s forwards

    & > span
        transform: translate3d(0, 138%, 0)
        animation: swipe-up .7s $secondary-ease-in .3s forwards

.artistsCover
    @include scaleWidthHeight($modal-main-cover-dim, $modal-main-cover-dim)
    position: relative
    overflow: hidden
    clip-path: inset(0px 100% 100% 0px round $medium-border-radius $medium-border-radius)
    animation: mask-out .7s $secondary-ease-in forwards

    & > div
        @include fullAbsolute
        background-size: cover
        background-position: center
        transform: scale3d(2.2, 2.2, 2.2)
        animation: main-cover-scale-down 1.5s $secondary-ease-in forwards

.playlistButton
    background: $mild-green
    opacity: 0
    transform: translate3d(0, 100px, 0)
    animation: swipe-up .7s $secondary-ease-in .1s forwards

    & > span
        transform: translate3d(0, 205%, 0)
        animation: swipe-up .7s $secondary-ease-in .1s forwards

.spotifyButton
    background: $dark-ash
    color: $green
    opacity: 0
    transform: translate3d(0, 100px, 0)
    animation: swipe-up .7s $secondary-ease-in .2s forwards

    &:hover
        color: $green

    path
        fill: $green

    & > span
        transform: translate3d(0, 205%, 0)
        animation: swipe-up .7s $secondary-ease-in .2s forwards

.genres
    margin: 15px 0 5px

.quickStats
    display: flex
    margin-bottom: 25px

    & > div
        &:nth-child(1)
            margin-right: 15px
            animation-delay: .4s

            & > span svg
                animation-delay: .4s

        &:nth-child(2)
            animation-delay: .5s

            & > span svg
                animation-delay: .5s

.avatarIcon
    width: 27px
    height: 27px

@keyframes mask-out
    to
        clip-path: inset(0px 0px 0px 0px round $medium-border-radius)

@keyframes main-cover-scale-down
    to
        transform: scale3d(1, 1, 1)

@keyframes swipe-up
    to
        opacity: 1
        transform: translate(0, 0)