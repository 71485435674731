@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.mobileTitleCard
	margin: 25px 8vw 0
	height: $mobile-title-card-height
	background: $mild-dark-ash
	border-radius: $medium-border-radius
	display: flex
	overflow: hidden
	opacity: 1
	transform: translate3d(0, 0, 0)
	transition: .7s $secondary-ease-in

.isPassive
	opacity: 0
	transform: translate3d(0, 200px, 0)

	.currentIcon > span
		display: none

	.statsContent
		h4, p
			transform: translate3d(0, 100px, 0)

.statsIcon
	width: 100px
	flex-basis: 100px
	display: inline-flex
	justify-content: center
	align-items: center

.currentIcon
	& > span
		display: inline-block
		opacity: 1
		transform: scale(1)
		animation: scale-up .7s $secondary-ease-in

.previousIcon
	position: absolute

	& > span
		display: inline-block
		opacity: 0
		transform: scale(0)
		animation: scale-down .3s $secondary-ease-in

.statsContent
	width: calc(100% - 100px)
	flex-basis: calc(100% - 100px)
	display: flex
	flex-direction: column
	justify-content: center

	h4
		margin: 0 0 5px
		color: $mild-gray
		font-size: $regular-text
		line-height: 1.31
		font-weight: 500
		opacity: 1
		transform: translate3d(0, 0, 0)
		transition: .7s $secondary-ease-in .1s

	p
		padding-right: 40px
		font-size: $mobile-quick-stats-text
		font-family: 'Circular Std'
		letter-spacing: -0.76px
		line-height: 1.25
		font-weight: 700
		opacity: 1
		transform: translate3d(0, 0, 0)
		transition: .7s $secondary-ease-in .2s

.currentStatsContent
	h4
		animation: swipe-up .7s $secondary-ease-in

	p
		animation: swipe-value-up .8s $secondary-ease-in

.prevStatsContent
	position: absolute
	top: 0
	right: 0
	bottom: 0
	left: 100px
	opacity: 0
	animation: fade-out .3s $secondary-ease-in

@keyframes scale-up
	from
		opacity: 0
		transform: scale(0)

@keyframes scale-down
	from
		opacity: 1
		transform: scale(1)

@keyframes swipe-up
	from
		opacity: 0
		transform: translate3d(0, 100px, 0)

@keyframes swipe-value-up
	0%, 12.5%
		opacity: 0
		transform: translate3d(0, 100px, 0)
		animation-timing-function: $secondary-ease-in

@keyframes fade-out
	from
		opacity: 1
