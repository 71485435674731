@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.vectorWrapper
	svg
		transform: none !important

.popularity
	display: inline-block
	width: 44px
	height: 44px

	@include respondTo('size-sm')
		@include scaleWidthHeight(34px, 34px)

.underground
	display: inline-block
	width: 44px
	height: 44px

	@include respondTo('size-sm')
		@include scaleWidthHeight(36px, 36px)

.trending
	display: inline-block
	width: 48px
	height: 48px

	@include respondTo('size-sm')
		@include scaleWidthHeight(45px, 45px)
		transform: translateX(-25%)

.spotify
	display: inline-block
	width: 30px
	height: 30px

.noPointer
	pointer-events: none