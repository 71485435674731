@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.container
    @include gridContainer(15)

.sectionIcon
    grid-area: 3 / 3 / 4 / 4

.statsSummary
    @include innerGridContainer(7)
    grid-area: 4 / 3 / 10 / 10
    z-index: 20

.isPassive
    .statsCaption, .statsSubcaptionText
        transform: translate(0, 100px)

        & > span
            transform: translate(0, 100%)
            opacity: 0

.statsSummaryContent
    @include fullAbsolute
    z-index: 20

.statsCaption
    @include sectionCaptionText
    transform: translate(0, 0)
    transition: 1s $secondary-ease-in .1s
    pointer-events: none

    & > span
        display: inline-block
        opacity: 1
        transform: translate(0, 0)
        transition: 1s $secondary-ease-in .1s

.greenText
    color: $green

    @include hoverFocusActiveState
        color: $green

.statsSubcaption
    @include sectionSubcaptionText
    transform: translate(0, 0)
    transition: 1s $secondary-ease-in .2s

.statsSubcaptionText
    display: inline-block
    opacity: 1
    transform: translate(0, 0)
    transition: 1s $secondary-ease-in .2s

.featuresChart
    grid-area: 3 / 10 / 9 / 16
