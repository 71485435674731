@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.listNav
    @include mobileModalContentNav

    span
        display: flex
        align-items: center
        font-size: $regular-text
        font-family: 'Circular Std'
        line-height: 1.25
        letter-spacing: -0.67px
        font-weight: 500
        opacity: 1
        transform: translate3d(0, 0, 0)

        &.isActiveNavControl
            color: $green
            transition: .5s $secondary-ease-in .1s

            path
                fill: $green
                transition: .5s $secondary-ease-in .1s

        &.isPassiveNavControl
            opacity: 0
            transition: .3s $secondary-ease-in

    svg
        margin-right: 5px

.openFilter
    transition: .7s $secondary-ease-in .35s

    &.isActiveNavControl
        transform: translate3d(-25px, 0, 0)

        @include respondTo('size-xs')
            transform: translate3d(-38px, 0, 0)

.openShare
    margin-left: 14px
    transition: .7s $secondary-ease-in .4s

    &.isActiveNavControl
        transform: translate3d(-107px, 0, 0)

        @include respondTo('size-xs')
            transform: translate3d(-120px, 0, 0)

.hideNav
    span
        opacity: 0
        transform: translate3d(0, 60px, 0)

        &.openFilter
            transition: .5s $secondary-ease-in .07s

        &.openShare
            transition: .5s $secondary-ease-in .035s

.collapsedNav
    span
        transition: .5s $secondary-ease-in .3s

        path
            transition: .5s $secondary-ease-in .3s

.filterForm
    position: absolute
    right: 0
    left: 0
    padding: 0 30px
    transition: .3s $secondary-ease-in

    & > h2
        margin: 0 0 10px
        font-size: $mobile-modal-form-title-text
        font-family: 'Circular Std'
        line-height: 1.33
        letter-spacing: -1.23px
        font-weight: 900
        opacity: 0
        transform: translate3d(0, 200px, 0)
        animation: swipe-up .5s $secondary-ease-in forwards

    legend
        font-size: $medium-text
        line-height: 1.29
        opacity: 0
        transform: translate3d(0, 200px, 0)
        animation: swipe-up .5s $secondary-ease-in forwards

    fieldset
        &:nth-of-type(1)
            legend
                animation-delay: .1s

        &:nth-of-type(2)
            legend
                animation-delay: .2s

        &:nth-of-type(3)
            legend
                animation-delay: .3s

        & > div
            display: grid
            grid-column-gap: 5px

            @include respondTo('size-xs')
                grid-column-gap: 15px

            & > label
                opacity: 0
                transform: translate3d(0, 200px, 0)
                animation: swipe-up .5s $secondary-ease-in forwards

    .cardRadio
        padding: 14px 5px
        font-size: $regular-text
        line-height: 1.375
        letter-spacing: -0.4px
        font-weight: 700

    .iconCardRadio
        height: $mobile-card-radio-height
        padding: 20px 2px 10px

        & > svg
            width: 40px
            height: 40px

.exitFilterForm
    opacity: 0

.actionButton
    position: absolute
    top: -62px
    right: 30px
    padding: 12px 22px
    background: $mild-green
    color: $white
    font-size: $regular-text
    line-height: 1.375
    letter-spacing: normal
    border-radius: $large-border-radius
    opacity: 0
    transform: translateZ(0)
    animation: fade-in .3s forwards

.timeOptions
    margin: 10px 0 20px
    grid-template-columns: 1fr 1fr 1fr

    & > label
        &:nth-child(1)
            animation-delay: .15s

        &:nth-child(2)
            animation-delay: .2s

        &:nth-child(3)
            animation-delay: .25s

.sortOptions
    margin: 12px 0 20px
    grid-template-columns: 1fr 1fr 1fr

    & > label
        &:nth-child(1)
            animation-delay: .25s

        &:nth-child(2)
            animation-delay: .3s

        &:nth-child(3)
            animation-delay: .35s

.orderOptions
    margin: 10px 0 20px
    grid-template-columns: 1fr 1fr

    & > label
        &:nth-child(1)
            animation-delay: .35s

        &:nth-child(2)
            animation-delay: .4s

.coversContainer
    position: absolute
    left: 0
    right: 0
    height: 100vw
    clip-path: inset(0 0 0 0)
    overflow: hidden

    @include after
        position: absolute
        top: 0
        right: 0
        left: 0
        bottom: -2px
        opacity: 0
        background: linear-gradient(rgba(25,25,26,0) 0%, #19191A 100%)
        animation: fade-in 0s .2s forwards

    & > div
        height: 100%

.listCovers
    @include fullAbsolute
    perspective: 465px
    perspective-origin: 50% 50%
    opacity: 0
    transform: scale(1.1)
    transition: .7s $secondary-ease-in
    animation: fade-in .7s $secondary-ease-in .2s forwards

    & > div
        position: absolute
        top: 0
        width: 100%
        height: 100%

        &:nth-child(1)
            background: $pure-black

.scaleCoversUp
    transform: scale(1.68)

.listCover
    & > div
        width: 100%
        height: 100%
        background-size: cover
        background-position: center
        will-change: opacity, transform

    &:nth-child(n+2)
        & > div
            opacity: 0
            transform: scale(2.66)
            animation: fade-cover-in 1s $secondary-ease-in forwards

    &:nth-child(n+3)
        mix-blend-mode: overlay

    @for $i from 0 through 3
        &:nth-child(#{$i + 2})
            transform: translate3d(0, 0, $i * 33px)

            & > div
                animation-delay: $i * .3s + .2s

.listSummary
    position: relative
    padding: 30px 8vw 0
    text-align: center
    z-index: 5

    & > div
        opacity: 1
        transition: .7s $primary-ease
        transform: translateZ(0)

.fadedSummary
    & > div
        opacity: .5
        transition: .5s $primary-ease

.listIcon
    opacity: 0
    transform: translate3d(0, 200px, 0)
    animation: swipe-up .7s $secondary-ease-in .2s forwards

    svg
        width: 44px
        height: 44px

.title
    margin: 20px 0 0
    font-size: $mobile-modal-title-text
    font-family: 'Circular Std'
    line-height: 1.17
    letter-spacing: -2.88px
    font-weight: 900

    span
        display: inline-block
        opacity: 0
        transform: translate3d(0, 200px, 0)
        animation: swipe-up .7s $secondary-ease-in .2s forwards

        &:last-child
            color: $green
            animation-delay: .3s

.descriptionPill
    display: inline-flex
    align-items: center
    margin: 10px 0 20px
    padding: 0 20px
    height: $mobile-pill-height
    border-radius: $regular-border-radius
    background: transparentize($white, .8)
    font-size: $regular-text
    line-height: 1.5
    font-weight: 500
    opacity: 0
    transform: translate3d(0, 200px, 0)
    animation: swipe-up .7s $secondary-ease-in .4s forwards

.playlistButton
    margin-top: 0
    height: $modal-btn-height
    background: $mild-green
    font-size: $sm-title-text
    line-height: 1.33
    opacity: 0
    transform: translate3d(0, 200px, 0)
    animation: swipe-up .7s $secondary-ease-in .5s forwards

    svg
        transform: scale(1)

.listGrid
    padding: 16px 8vw
    display: grid
    grid-template-columns: auto auto
    grid-gap: 15px
    margin-bottom: 15px

@keyframes fade-in
    to
        opacity: 1

@keyframes fade-cover-in
    to
        opacity: 0.5
        transform: scale(1)

@keyframes swipe-up
    to
        opacity: 1
        transform: translate3d(0, 0, 0)
