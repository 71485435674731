@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.header
    position: fixed
    top: 0
    right: 0
    left: 0
    max-height: 10vh
    padding: 25px 30px 25px 30px
    display: flex
    justify-content: space-between
    align-items: center
    z-index: 90

    &.landingHeader
        width: 50vh

.isPassive
    opacity: 0
    pointer-events: none

    .nav
        & > svg, li
            opacity: 0

.nav
    display: flex
    align-items: center

    & > svg
        margin-right: 20px
        @include scaleWidthHeight(30px, 30px)
        transition: opacity .7s $secondary-ease-in

    ul
        display: none

        @include respondTo('size-md')
            display: flex

        li > span
            background: transparentize($green, 1)
            padding: 7px 20px 7px 10px
            display: flex
            align-items: center
            border-radius: $large-border-radius
            @include responsiveHeightText($sm-title-text)
            line-height: 1.28
            letter-spacing: -0.75px
            font-family: 'Circular Std'
            font-weight: 500
            transition: .7s $secondary-ease-in

            &.isActive
                background: transparentize($green, .85)
                color: $green

                path
                    fill: $green

            &:nth-child(1)
                transition-delay: .1s

            &:nth-child(2)
                transition-delay: .2s

            & > svg
                margin-right: 8px
                @include scaleWidthHeight(30px, 30px)
                transition: opacity .7s $secondary-ease-in

            path
                transition: .7s $secondary-ease-in

.menuPill
    position: absolute
    left: 50%
    @include scaleWidthHeight($menu-width, $menu-height)
    background: $dark-green-alt
    border-radius: $regular-border-radius
    overflow: hidden
    transform: translateX(-50%)

.statsProgress
    @include fullAbsolute
    background: $dark-green
    transform: scale3d(0, 1, 1)
    transform-origin: left

.menuTitles
    display: flex
    flex-direction: column
    transition: .7s $secondary-ease-in
    position: relative
    z-index: 10

    span
        @include scaleHeight($menu-height)
        @include fullCenter
        @include responsiveHeightText($regular-text)
        line-height: 1.33
        font-weight: 700

.userProfile
    display: flex
    text-align: right
    align-items: center

.username
    display: none

    @include respondTo('size-md')
        display: block
        margin-right: 20px
        @include responsiveHeightText($sm-title-text)
        line-height: 1.28
        letter-spacing: -0.6px
        font-family: 'Circular Std'
        font-weight: 500

.userAvatar
    @include scaleWidthHeight($avatar-dimension, $avatar-dimension)
    background: $green
    border-radius: 50%
    background-size: cover
    background-position: center

.menuIcon
    @include scaleWidth(45px)
    display: inline-flex
    justify-content: flex-end

    &.isActive
        & > span
            background: transparentize($green, .85)

        path
            fill: $green

    & > span
        background: transparentize($green, 1)
        @include scaleWidthHeight(35px, 35px)
        @include fullCenter
        border-radius: 50%
        transition: .7s $secondary-ease-in

    svg
        @include scaleWidthHeight(30px, 30px)

    path
        fill: $white
        transition: .7s $secondary-ease-in

.aboutMenu
    @include scaleWidth($about-menu-width)
    top: 10vh

    & > div
        padding: 4.5% 7.45% 3%
        transform: translateZ(0)

    h3
        @include responsiveHeightText($x-large-text)
        line-height: 1.25
        letter-spacing: -2.22px
        font-family: 'Circular Std'
        font-weight: 900
        opacity: 0
        transform: translate(0, 230px)
        animation: swipe-up .7s $secondary-ease-in .1s forwards

    h4
        margin-bottom: 5px
        color: $mild-gray
        @include responsiveHeightText($regular-text)
        line-height: 1.25
        letter-spacing: -0.4px
        font-family: 'Circular Std'
        font-weight: 700
        opacity: 0
        transform: translate(0, 200px)
        animation: swipe-up .7s $secondary-ease-in .3s forwards

        &:nth-of-type(2)
            margin-bottom: 15px
            animation-delay: .5s

.description
    margin: 10px 0 25px
    @include responsiveHeightText($sm-title-text)
    line-height: 1.8
    opacity: 0
    transform: translate(0, 205px)
    animation: swipe-up .7s $secondary-ease-in .2s forwards

    &:nth-of-type(2)
        margin: 0 0 25px
        animation-delay: .4s

    a
        color: $green

        @include hoverFocusActiveState
            color: $green

.resources
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-column-gap: 3.7%

.resource
    display: flex
    background: $dark-ash
    border-radius: $medium-border-radius
    opacity: 0
    transform: translate(0, 200px)
    animation: swipe-up .7s $secondary-ease-in .6s forwards
    overflow: hidden

.resourceBrand
    @include scaleHeight($resource-card-height)
    width: 27%
    flex-basis: 27%
    @include fullCenter

    & > span
        opacity: 0
        transform: translate(0, 60px)
        animation: swipe-up .7s $secondary-ease-in .6s forwards

    svg
        @include scaleWidthHeight(30px, 30px)
        transform: scale(2.5)
        animation: scale-down .7s $secondary-ease-in .6s forwards

.studio
    background: $red

.github
    background: $pure-black

.resourceInfo
    display: flex
    flex-direction: column
    justify-content: center
    padding: 0 7.3%

    h6
        @include responsiveHeightText($sm-title-text)
        line-height: 1.28
        letter-spacing: -0.4px
        font-family: 'Circular Std'
        font-weight: 700
        opacity: 0
        transform: translate(0, 60px)
        animation: swipe-up .7s $secondary-ease-in .6s forwards

    span
        color: $mild-gray
        @include responsiveHeightText($regular-text)
        line-height: 1.5
        font-weight: 500
        opacity: 0
        transform: translate(0, 60px)
        animation: swipe-up .7s $secondary-ease-in .7s forwards

.aboutMenuFooter
    margin-top: 18px
    display: flex
    justify-content: space-between
    align-items: center

    svg
        @include scaleWidthHeight(124px, 19px)
        opacity: 0
        transform: translate(0, 200px)
        animation: swipe-up .7s $secondary-ease-in .7s forwards

.version
    color: $mild-gray
    @include responsiveHeightText($medium-text)
    line-height: 1.36
    font-weight: 700
    opacity: 0
    transform: translate(0, 170px)
    animation: swipe-up .7s $secondary-ease-in .8s forwards

.shareMenu
    @include scaleWidth($share-menu-width)
    top: 10vh

    & > div
        padding: 6% 10% 9%
        transform: translateZ(0)

    h3
        @include responsiveHeightText($x-large-text)
        line-height: 1.25
        letter-spacing: -2.22px
        font-family: 'Circular Std'
        font-weight: 900
        opacity: 0
        transform: translate(0, 200px)
        animation: swipe-up .7s $secondary-ease-in .1s forwards

    p
        color: $mild-gray
        margin: 5px 0 20px
        @include responsiveHeightText($regular-text)
        line-height: 1.3
        font-weight: 500
        opacity: 0
        transform: translate(0, 200px)
        animation: swipe-up .7s $secondary-ease-in .2s forwards

.sharePlatform
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-column-gap: 4%

.shareCard
    opacity: 0
    transform: translate(0, 200px)
    animation: swipe-up .7s $secondary-ease-in .3s forwards

    &:nth-child(2)
        animation-delay: .35s

    &:nth-child(3)
        animation-delay: .4s

.copyToast
    position: absolute
    right: 10%
    display: inline-block
    @include responsiveHeightText($medium-text)
    color: $green
    opacity: 0
    transform: translateY(15px)
    animation: fade-in .7s $secondary-ease-in forwards

.userMenu
    @include scaleWidth($user-menu-width)
    top: 10vh
    right: 25px

    ul
        transform: translateZ(0)

    li
        @include scaleHeight(60px)
        display: flex
        justify-content: space-between
        align-items: center
        padding: 0 8%
        @include responsiveHeightText($sm-title-text)
        line-height: 1.66
        letter-spacing: -0.4px
        font-family: 'Circular Std'
        font-weight: 500
        opacity: 0
        transform: translate(0, 170px)
        animation: swipe-up .7s $secondary-ease-in forwards

        &:nth-child(2)
            animation-delay: .1s

        &:nth-child(3)
            animation-delay: .2s

        &:nth-child(4)
            animation-delay: .3s

        & > span, & > a
            display: flex

        svg
            @include scaleWidthHeight(30px, 30px)
            margin-right: 10px

@keyframes swipe-up
    to
        opacity: 1
        transform: translate(0, 0)

@keyframes scale-down
    to
        transform: scale(1)

@keyframes fade-in
    to
        opacity: 1
