@import '../../sass/abstracts/variables.sass'
@import '../../sass/abstracts/mixins.sass'

.insufficientData
	@include gridContainer(17)

.ghostIcon
	@include innerGridContainer(3)
	grid-area: 3 / 4 / 7 / 7
	pointer-events: none

.ghostIconContent
	@include fullAbsolute
	z-index: 20
	@include fullCenter

	& > span
		@include scaleWidthHeight($insufficient-data-ghost-dim, $insufficient-data-ghost-dim)

.caption
	@include innerGridContainer(7)
	grid-area: 3 / 7 / 6 / 14
	pointer-events: none

.captionContent
	@include fullAbsolute
	z-index: 20

	h1
		margin: 1vh 0 0
		@include responsiveHeightText($caption-text)
		font-family: 'Circular Std'
		font-weight: 900
		line-height: 1.27
		letter-spacing: -5px
		overflow: hidden
		transform: translate3d(0, 0, 0)
		transition: opacity 1s $secondary-ease-in .1s

		span
			display: inline-block
			opacity: 1
			transform: translate3d(0, 0, 0)
			transition: opacity 1s $secondary-ease-in .1s, transform 1s $secondary-ease-in .1s

.greenText
	color: $green

.description
	@include innerGridContainer(7)
	grid-area: 6 / 7 / 8 / 14
	pointer-events: none

.descriptionContent
	@include fullAbsolute
	z-index: 20

	p
		padding: 0 10vh 0 0
		@include responsiveHeightText($sub-caption-text)
		line-height: 1.7
		overflow: hidden
		transform: translate3d(0, -.2em, 0)
		transition: opacity 1s $secondary-ease-in .1s

		span
			display: inline-block
			opacity: 1
			transform: translate3d(0, 0, 0)
			transition: opacity 1s $secondary-ease-in .1s, transform 1s $secondary-ease-in .1s

.projectPlaylist
	grid-area: 8 / 7 / 9 / 12
